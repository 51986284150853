import React from 'react';
import PropTypes from 'prop-types';

const Frame1 = (props) => {
    const { className = '', overrides, isFrameOpen, onClose, ...rest } = props;
    if (!isFrameOpen) {
        return null;
    }
    return (
        <div className={props.isAddressSelected ? "ParkingSpots" : `frame-parent ${className}`} {...rest}>
            <div className="group-container">
                <img className="group-icon" alt="" src="Group91.png" />
                <b className="locate-property">
                    {props.isAddressSelected ? "MARK PARKING SPOTS AND ENTRANCES" : "LOCATE PROPERTY"}
                </b>
            </div>
            <div className={props.isAddressSelected ? "Markspots-Content" : "to-mark-pick-up"}>
                {props.isAddressSelected ?
                    "Select Points from the dropdown to mark on the map. Mark as many points as you need on the map. Pick parking spots/spaces that are most likely to be vacant/open during the times you are selecting. It is not required that you ensure the parking spaces are for sure open because if they are all full when a Robotaxi comes to park there, then the Robotaxi comes to park there, then the Robotaxi will simply just drive away to another GeoParking parking lot."
                    :
                    "To mark pick-up and drop-off areas or parking spaces, you need to locate your property first. Use the search bar to enter your address, or use your current location to find your property on the map"}
            </div>
            <div className={props.isAddressSelected ? "close-Markspots" : "close-wrapper"} onClick={onClose}>
                <b className="close">Close</b>
            </div>
        </div>
    );
};

Frame1.propTypes = {
    className: PropTypes.string,
    overrides: PropTypes.object,
    isFrameOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Frame1;
