import React, { useState } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isDisabled } from '@testing-library/user-event/dist/utils';

const ResetPassword = () => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const navigate = useNavigate();
    const [loginUserData, setloginUserData] = useState({
        EmailId: '',
    });
    const [EmailForRestPassword, sendingEmailForRestPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        Password: '',
        ConfirmPassword: '',
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Add this line

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            let resetEmail = {
                EmailId: loginUserData.EmailId.trim().toLowerCase(),
                TypeOfEmail: "emailhasbeensend",
                DomainName: window.location.origin
            };
            try {
                const response = await axios.post(`${hostPath}/api/SendEmailConfirmation`, resetEmail, {
                    headers: { "x-functions-key":  `${hostHeader}`  }
                });
    
                if (response.data) {
                    console.log("response.data", response.data);
                    if(response.data.status === false){
                        setErrors({ ...errors, EmailId: response.data.responseMessage});

                    }
                    else{
                        sendingEmailForRestPassword(true);
                        setIsButtonDisabled(true); 
                    }
                } else {
                    setErrors({ ...errors, Password: 'Please enter the correct password and try again!' });
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    

    const validatePasswordForm = () => {
        const errors = {};
        if (!formData.Password) {
            errors.Password = 'Password is required';
        } else if (formData.Password.length < 8) { // Check if the length of the password is less than 8
            errors.Password = 'Password must be at least 8 characters long';
        } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(formData.Password)) {
            errors.Password = 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character';
        }

        if (!formData.ConfirmPassword) {
            errors.ConfirmPassword = 'Confirm Password is required';
        } else if (formData.Password !== formData.ConfirmPassword) {
            errors.ConfirmPassword = 'please match the Passwords';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const ChangePasswordButtonClick = (e) => {
        e.preventDefault();
        if (validatePasswordForm()) {
           alert ("Password changed successfully")
        }
    }


    const validateForm = () => {
        const errors = {};
        if (!loginUserData.EmailId.trim()) {
            errors.EmailId = 'Email is required';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleButtonClick1 = () => {
        navigate('/');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedValue = name === 'email' ? value.trim() : value;
        setFormData({ ...formData, [name]: updatedValue });
        clearError(name); // Clear error for the corresponding input field
    };

    const clearError = (fieldName) => {
        setErrors((prevErrors) => {
            return { ...prevErrors, [fieldName]: '' };
        });
    };

    const accountinfopage = () => {
        navigate('/');
    }

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-7 p-0">
                    <img src={RobotaxiLogin} alt="Login" style={{ width: "100%", height: "100vh" }} />
                </div>
                <div className="col-md-5 d-flex align-items-center justify-content-center">
                    <div className="login-form loginfirst">
                        <div className="login">
                            <h3 className='loginheader textleft'>Reset Your Password</h3>
                            {
                                EmailForRestPassword ?
                                    <p className="mb-4 loginDescription textleft">Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.</p> :
                                    <p className="mb-4 loginDescription textleft">Forgotten your password? Enter your registered email id to reset it now.</p>
                            }
                        </div>
                        {
                            EmailForRestPassword ? null :
                                <div className="otp-input mb-4">
                                    <div className="Username mb-4 textleft formbox">
                                        <label htmlFor="username" className='userName loginlabel'>Email</label><span className='mandatorymark loginlabel'>*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${loginUserData.EmailId ? 'input-active' : ''}`}
                                            id="username"
                                            placeholder='Enter Email'
                                            value={loginUserData.EmailId}
                                            onChange={(e) => {
                                                clearError('EmailId');
                                                const trimmedEmail = e.target.value.trim();
                                                setloginUserData({ ...loginUserData, EmailId: trimmedEmail });
                                            }}
                                        />
                                        {errors.EmailId && <div className="error-message mandatorymark">{errors.EmailId}</div>}
                                    </div>
                                </div>
                        }
                        <div className="d-flex justify-content-between mb-5">
                            {
                                EmailForRestPassword ?
                                    <button type="button" className='forgotpasswordbutton' onClick={handleButtonClick1}>Return to sign in</button> :
                                    <button type="button" className='forgotpasswordbutton' onClick={handleButtonClick} disabled={isButtonDisabled}>Send password reset email</button>
                            }
                        </div>
                    </div>


                    {/* <div className="login-form loginfirst">
                        <div className="login">
                            <h3 className='loginheader textleft'>Change password</h3>
                            <p className="mb-4 loginDescription textleft">Make sure password is minimum 8 characters including a number and a uppercase letter.</p>
                        </div>
                                
                        <div className="d-flex justify-content-between mb-5">
                                    <button type="button" className='forgotpasswordbutton' onClick={handleButtonClick1}>Return to sign in</button> 
                        </div>
                    </div> */}


                    {/* <form className="login-form ">
                            <div className=" login">
                                <h3 className='loginheader textleft'>Change password</h3>
                                <p className="mb-4 loginDescription textleft">Make sure password is minimum 8 characters including a number and a uppercase letter.</p>
                            </div>
                            <div className="Password mb-3 textleft">
                                <label htmlFor="password" className='userName loginlabel'>Password</label><span className='mandatorymark loginlabel'>*</span>
                                <input
                                    type="password"
                                    className={`form-control ${formData.Password? 'input-active' : ''}`}
                                    id="password"
                                    placeholder='Enter Password'
                                    name="Password"
                                    value={formData.Password}
                                    onChange={handleChange}
                                />
                                {errors.Password && <div className="error-message mandatorymark">{errors.Password}</div>}
                            </div>
                            <div className="Password mb-3 textleft">
                                <label htmlFor="confirmPassword" className='userName loginlabel'>Confirm Password</label><span className='mandatorymark loginlabel'>*</span>
                                <input
                                    type="password"
                                    className={`form-control ${formData.ConfirmPassword ? 'input-active' : ''}`}
                                    id="confirmPassword"
                                    placeholder='Confirm Password'
                                    name="ConfirmPassword"
                                    value={formData.ConfirmPassword}
                                    onChange={handleChange}
                                />
                                {errors.ConfirmPassword && <div className="error-message mandatorymark">{errors.ConfirmPassword}</div>}
                            </div>
                            <div className="d-flex justify-content-between mb-3">
                                <button type="submit" className='forgotpasswordbutton' onClick={ChangePasswordButtonClick} >Change passwords</button>
                            </div>
                        </form> */}




                    {/* <div className="login-form loginfirst" >
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0926 145.799C43.9006 120.43 24.0286 88.2168 24.0286 54.9155V39.6693L80.0926 14.7271L136.157 39.6693V54.9155C136.157 88.2168 116.285 120.43 80.0926 145.799Z" fill="#E6E9ED" />
                                    <path d="M80.0925 99.1928C95.368 99.1928 107.751 86.8096 107.751 71.5342C107.751 56.2587 95.368 43.8755 80.0925 43.8755C64.817 43.8755 52.4338 56.2587 52.4338 71.5342C52.4338 86.8096 64.817 99.1928 80.0925 99.1928Z" fill="#82D65F" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M75.0615 83.6622C74.4997 83.6622 73.9628 83.4382 73.5682 83.0435L65.0881 74.5635C64.2633 73.7386 64.2633 72.3982 65.0881 71.5733C65.913 70.7484 67.2535 70.7484 68.0784 71.5733L75.0615 78.5564L91.5593 62.0586C92.3842 61.2337 93.7246 61.2337 94.5495 62.0586C95.3744 62.8835 95.3744 64.2239 94.5495 65.0488L76.5584 83.0399C76.1602 83.4382 75.6233 83.6622 75.0615 83.6622Z" fill="#E6E9ED" />
                                </svg>
                            </div>
                            <div className="login">
                                <h3 className='loginheader ' style={{ marginTop: "150px" }}>Password reset successfully !</h3>
                                <p className="mb-4 loginDescription">Your password have been reset successfully. We're redirecting you to the Sign in page!</p>
                            </div>
                            <div> <p className="mb-4 loginDescription">In case you are not redirected automatically  <a onClick={accountinfopage} className='joingeo'>click here</a></p></div>
                        </div> */}



                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
