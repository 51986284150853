import React, { useEffect, useState } from 'react';
import './MarkingAlert.css';

const MarkingAlert = (props) => {
  const { overrides, handleCancelalert, markingalert, exitAlert,handleexitalert,currentMarker,markersDatalen, ...rest } = props;
  const [area,setArea] = useState();
  const areaPair = {
		"PUE - Pick Up Entrance":  "PUX - Pick Up Exit",
		"DOE - Drop Off Entrance": "DOX - Drop Off Exit",
		"PUL - Pick Up Location": "DOL - Drop Off Location",
		"PKE - Parking Entrance": "PKX - Parking Exit"
	  }
    const findKeyByValue = (obj, valueToFind) => {
      for (const [key, val] of Object.entries(obj)) {
        if (val === valueToFind) {
        return key;
        }
      }
      return null;
      };
	useEffect(() => {
    const value =  areaPair[currentMarker];
    const key = findKeyByValue(areaPair,currentMarker);
		if(value){
      setArea(value);
			}
		else if(key){
		  setArea(key); 
		}	
  },[currentMarker])
  return (
    <div>
      {markingalert ? (
        <div className="alert-overlay">
          <div className="alert">
            <p>Mark Selected areas before proceeding</p>
            <div className="button-container">
              <button onClick={() => handleCancelalert(false)}>Cancel</button>
            </div>
          </div>
        </div>
      ) : (
        exitAlert && (
          <div className="custom-popup">
          <div className="popup-text">
              <p>Do you want to mark {markersDatalen} {currentMarker} points next to {area} points. You can always move these points around later?</p>
              <div className="popup-buttons">
                  <button className="confirm-btn" onClick={() => handleexitalert(false,true)}>
                      Continue
                  </button>
                  <button className="cancel-btn" onClick={() => handleexitalert(false,false)}>
                      Cancel
                  </button>
              </div>
          </div>
      </div>
        )
      )}
    </div>
  );
};

export default MarkingAlert;
