import React, { useState, useEffect } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

const Login = () => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [failedAttempts, setFailedAttempts] = useState(0);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [postId, setPostId] = useState(null);
    const [LogedInUser, setLogedInUser] = useState([])
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loginUserData, setLoginUserData] = useState({
        EmailId: '',
        Password: '',
        Rememberme: false
    });
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleCheckboxChange = () => {
        setLoginUserData({
            ...loginUserData,
            Rememberme: !loginUserData.Rememberme
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!loginUserData.EmailId) {
            errors.EmailId = 'Email is required';
        }
        if (!loginUserData.Password) {
            errors.Password = 'Password is required';
        } 
        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };

    const login = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const modifiedLoginUserData = {
                ...loginUserData,
                EmailId: loginUserData.EmailId.toLowerCase(),
            };
            const requestOptions = {
                method: 'POST',
                headers: { "x-functions-key": `${hostHeader}`},
                body: JSON.stringify(modifiedLoginUserData)
            };
            axios.post(`${hostPath}/api/LoginUser`, requestOptions.body, { headers: requestOptions.headers })
                .then(response => {
                    if (response.data[0]) {
                        setLogedInUser(response.data)
                        const LogedInUser = response.data
                       localStorage.setItem('LogedInUser', JSON.stringify(LogedInUser[0]?.emailId));
                       localStorage.setItem('firstName', JSON.stringify(LogedInUser[0]?.firstName));
                       localStorage.setItem('lastName', JSON.stringify(LogedInUser[0]?.lastName));
                       localStorage.setItem('EnityMailingAdderess', JSON.stringify(LogedInUser[0]?.enityMailingAdderess));
                      navigate('/dashborad');
                    }
                    else {
                        setFailedAttempts(failedAttempts + 1);
                        if (failedAttempts >= 5) {
                            setErrors({Password: 'Too many incorrect login attempts. Please try again in 3 minutes'});
                            setIsFormDisabled(true);
                            setTimeout(() => {
                                setIsFormDisabled(false);
                                setErrors({Password: ''}); // Clear the error message
                                setFailedAttempts(0); // Reset the failed attempts count
                            }, 180000); // Enable the form after 3 minutes
                        } else {
                            setErrors({ Password: 'Please enter a valid email/password' });
                        }
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    setErrors({ Password: 'Please enter a valid email/password' });
                });
        }
    };

    const forgotPassword = (e) => {
        e.preventDefault();
        navigate('/ResetPassword');
    }

    const handleChange = (e) => {
        setLoginUserData({ ...loginUserData, EmailId: e.target.value });
        setEmail(e.target.value);
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-7 p-0">
                    <img src={RobotaxiLogin} alt="Login" style={{ width: "100%", height: "100vh" }} />
                </div>
                <div className="col-md-5">
                    <form className="login-form loginfirst">
                        <div className="login">
                            <h3 className='loginheader textleft'>Login</h3>
                            <p className="mb-4 loginDescription textleft">Log in to your GeoParking Technologies Dashboard</p>
                        </div>
                        <div className="Username mb-4 textleft formbox">
                            <label htmlFor="username" className='userName loginlabel'>Email</label><span className='mandatorymark loginlabel'>*</span>
                            <input type="text"
                                className={`form-control ${loginUserData.EmailId ? 'input-active' : ''}`}
                                id="username"
                                placeholder='Enter Email'
                                value={loginUserData.EmailId}
                                onChange={handleChange}
                                disabled={isFormDisabled}
                            />
                            {errors.EmailId && <div className="error-message mandatorymark">{errors.EmailId}</div>}
                        </div>
                        <div className="Password mb-3 textleft">
                            <style>
                                {`
                                input::-ms-reveal {
                                display: none;
                                }
                                `}
                            </style>                            
                            <label htmlFor="password" className='userName loginlabel'>Password</label><span className='mandatorymark loginlabel'>*</span>
                            <div className="password-container" style={{ position: 'relative' }}>
                                <input type={passwordVisible ? "text" : "password"}
                                    className={`form-control ${loginUserData.Password ? 'input-active' : ''}`}
                                    id="password"
                                    placeholder='Enter Password'
                                    value={loginUserData.Password}
                                    onChange={(e) => setLoginUserData({ ...loginUserData, Password: e.target.value })} 
                                    disabled={isFormDisabled} />
                                <IconButton
                                data-testid="toggle-password-visibility"
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer'
                                }}
                            >
                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </div>
                            {errors.Password && <div className="error-message mandatorymark">{errors.Password}</div>}
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="remember">
                                <label className="control control--checkbox mb-0">
                                    <input type="checkbox"
                                        checked={loginUserData.Rememberme}
                                        onChange={handleCheckboxChange} /> <span className="caption">Remember me</span>
                                    <div className="control__indicator"></div>
                                </label>
                            </div>
                            <div className="forgot-pass">
                                <a href="" onClick={forgotPassword}>Forgot Password</a>
                            </div>
                        </div>
                        <button type="submit" className='btnn-primary mb-3 login-button' onClick={login}  disabled={isFormDisabled}>Login</button>
                        <div className='joinus remember'> New to GeoParking? <Link to='/signup' className='joingeo'> Join us now</Link>  and be part of the future!</div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
