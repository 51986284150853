import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../../Images/GeoParkingLogo.png'; // Adjust the path as necessary
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';

const Navbar = ({ mapvisible }) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const firstInitial = firstName && firstName.length > 1 ? firstName?.charAt(1) : ''; 
    const lastInitial = lastName && lastName.length > 1 ? lastName?.charAt(1) : '';
    const initials = firstName&&lastName ? (firstInitial + lastInitial).toUpperCase():'';
    const [isDropdownVisible, setIsDropdownVisible] = useState(false); 
    const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
    const handlemypropertiesClick = (e) => {
        if (mapvisible) {
            e.preventDefault();
            setShowPopup(true);
        } else {
            navigate('/viewparkinginfo');
        }
    };

    const handleConfirmNavigate = () => {
        setShowPopup(false);
        navigate('/viewparkinginfo');
    };

    const handleCancelNavigate = () => {
        setShowPopup(false);
    };

    return (
        <nav className="navbar">
            {/* Navbar logo and links */}
            <div className="navbar-logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="navbar-links">
                <NavLink
                    to="/dashborad"
                    className={({ isActive }) => (isActive ? 'selected' : '')}
                    
                >
                    Dashboard
                </NavLink>
                <NavLink
                    to="/viewparkinginfo"
                    className={({ isActive }) => (isActive ? 'selected' : '')}
                    onClick={handlemypropertiesClick}
                >
                    My Properties
                </NavLink>
                <NavLink
                    to="/earnings"
                    className={({ isActive }) => (isActive ? 'selected' : '')}
                >
                    Earnings
                </NavLink>
            </div>
            <div className='logout-button'><LogoutIcon onClick={() => navigate('/')} /></div>
                <div className="navbar-profile" onClick={toggleDropdown}>
                    <div className="profile-icon">{initials}</div>
                    {isDropdownVisible && (
                    <div className="dropdown-menu">
                        <ul>
                        <li><Link to="/settings/entity">Entity Information</Link></li>
                        <li><Link to="/settings/profile">Profile Information</Link></li>
                        <li><Link to="/settings/password">Change Password</Link></li>
                        </ul>
                    </div>
                    )}
                </div>
            {showPopup && (
                <div className="custom-popup">
                    <div className="popup-text">
                        <p>All you progress for parking registration will be lost. Are you sure you want to continue?</p>
                        <div className="popup-buttons">
                            <button className="confirm-btn" onClick={handleConfirmNavigate}>
                                Continue
                            </button>
                            <button className="cancel-btn" onClick={handleCancelNavigate}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
