import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Login from './components/login/Login.jsx';
import EntityInformation from './components/login/entityinformation.jsx';
import EmailVerification from './components/login/emailVerification.jsx'
import PhoneVerification from './components/login/phoneVerification.jsx';
import AccountInformation from './components/login/accountInformation.jsx'
import Dashboard from './components/dashboard/dashborad.jsx'
import MyPropertiesHome from './components/myproporties/myProperties_Home.jsx'
import LocateProperty from './components/myproporties/locatePropert.jsx' 
import BankDetailsMain from './components/bankDetails/bankDetailsMain.jsx'
import ReviewAndConfirm from './components/reviewConfirm/reviewandConfirm.jsx'
import ScheduleAvailability from './components/scheduleAvailability/scheduleAvailability.jsx'
import ViewPrakingLotInfo from './components/reviewConfirm/viewParkingLotInfo.jsx'
import SignUP from './components/login/signUP.jsx' 
import ResetPassword from './components/ResetPassword/ResetPassword.jsx'
import ForgetPassword from './components/ResetPassword/ForgetPassword.jsx'
import LocatePropertNewScreen from './components/myproporties/LocatePropertNewScreen.jsx'
import MapDashboard  from './components/myproporties/MapDashboard.jsx'
import HomePage from './components/landingPage/HomePage.jsx';
import UserDelete from './components/UserDelete.jsx'
import PrivacyPolicy from './components/landingPage/components/PrivacyPolicy.jsx';
import TermsOfUse from './components/landingPage/components/TermsOfUse.jsx';
import TermsOfService from './components/landingPage/components/TermsOfService.jsx';
import UserVerify from './components/UserVerify.jsx';
import SettingEntityInfo from './components/Settings/SettingEntityInfo.jsx'
import Earnings from './components/dashboard/earnings.jsx';

const App = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUP />} />
                    <Route path="/entityinformation" element={<EntityInformation />} />
                    <Route path="/emailVerification" element={<EmailVerification />} />
                    <Route path="/phoneVerification" element={<PhoneVerification />} />
                    <Route path="/accountInformation" element={<AccountInformation />} />
                    {/* <Route path="/MarkArea" element={<MarkArea />} /> */}
                    <Route path="/dashborad" element={<Dashboard />} />
                    <Route path="/myProperties_Home" element={<MyPropertiesHome />} />
                    <Route path="/locateproperty" element={<MapDashboard />} />
                    {/* <Route path="/bankDetailsMain" element={<BankDetailsMain />} />
                    <Route path="/scheduleAvailability" element={<ScheduleAvailability />} /> */}
                    <Route path="/reviewandConfirm" element={<ReviewAndConfirm />} />
                    <Route path="/viewparkinginfo" element={<ViewPrakingLotInfo />} />
                    <Route path="/ResetPassword" element={<ResetPassword />} />
                    <Route path="/ForgetPassword" element={<ForgetPassword />} />
                    <Route path="/LocatePropertNewScreen" element={<LocatePropertNewScreen />} />
                    <Route path='/UserDelete' element={<UserDelete/> }/>
                    <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                    <Route path='/terms-of-use' element={<TermsOfUse/>} />
                    <Route path='/terms-of-service' element={<TermsOfService/>} />
                    <Route path='/UserVerify' element={<UserVerify/> }/>               
                    <Route path='/earnings' element={<Earnings/> }/>
                    <Route path='/SettingEntityInfo' element={<SettingEntityInfo/> }/>
                    <Route path="/settings/:section" element={<SettingEntityInfo />} />

=                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default App;
