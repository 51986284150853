import React, { useEffect } from 'react';
import * as atlas from 'azure-maps-control';
import '../styles/ContactUs.css';

const ContactUs = () => {
  useEffect(() => {
    const map = new atlas.Map('azureMap', {
      center: [-122.2003, 47.618390], // Corrected Longitude, Latitude of the location
      zoom: 15,
      language: 'en-US',
      authOptions: {
        authType: 'subscriptionKey',
        subscriptionKey: 'iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw'
      }
    });

    map.events.add('ready', () => {
      map.markers.add(new atlas.HtmlMarker({
        position: [-122.201240, 47.617669], // Corrected Longitude, Latitude of the location
        popup: new atlas.Popup({
          content: '<div style="padding:10px">800 Bellevue Way NE, Suite #500, Bellevue, WA 98004, USA</div>',
          pixelOffset: [0, -30]
        })
      }));
    });
  }, []);

  const openMapInNewTab = () => {
    window.open(`https://www.bing.com/maps?cp=47.616269~-122.200540&lvl=15&style=r&sp=point.47.616269_-122.200540_800%20Bellevue%20Way%20NE%2C%20Suite%20%23500%2C%20Bellevue%2C%20WA%2098004%2C%20USA`, '_blank');
  };

  return (
    <section className="contact-us" id="contact-us">
      <div className="contact-us-info">
        <div className="contact-us-details">
          <h2>Get In Touch</h2>
          <p className="contact-description">
            Interested in learning more about how GeoParking can help you contribute to a greener future? Contact us today!
          </p>
          <p className="contact-detail"><strong>Email:</strong> <a href="mailto:Parking@GeoParking.com" className="contact-link">Parking@GeoParking.com</a></p>
          <p className="contact-detail"><strong>Phone:</strong> <a href="tel:+14254598400" className="contact-link">+1-425-459-8400</a></p>
          <p className="contact-detail">
          <strong>Address:</strong> 
          <a href="https://www.bing.com/maps?q=800+Bellevue+Way+NE,+Suite+%23500,+Bellevue,+WA+98004,+USA" target="_blank" rel="noopener noreferrer">
            800 Bellevue Way NE, Suite #500, Bellevue, WA 98004, USA
          </a>
        </p>
        </div>
        <div className="contact-us-map">
          <div id="azureMap" className="azure-map"></div>
          <button className="map-button" onClick={openMapInNewTab}>Open Map in New Tab</button>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
