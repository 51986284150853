import React, { useEffect, useState } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import GeoParkingLogo from '../../Images/GeoParkingLogo.png';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormControl, InputLabel, OutlinedInput, TextField } from '@mui/material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const ScheduleAvailability = (props) => {
    const { overrides, handleradiobutton, selectedValue, timeValues, errors, handleChange, handleStepsback, handleSteps,bankdetails,EmailId,handlebankaccountdetails, timeSlotesAvailability,toggleCondition, ...rest } = props;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const handleButtonClick = (e) => {
        // if(timeSlotesAvailability()){
        // toggleCondition('4 of 5 - Enter Bank Details')
        // toggleCondition('3 of 5 - Schedule Availability')
        // }
        timeSlotesAvailability();

    };
    const backButtonClick = () => {
        props.handleConfirm(false);
            toggleCondition('2 of 5 - Mark Pick up and Drop off Area')
			toggleCondition('3 of 5 - Schedule Availability')
    }
    return (
        <div>
            <div className="col-md-10 ">
                <div className="row m-4"  >
                    <div className="col-lg-12 col-md-12 col-sm-12 m-3" style={{ textAlign: "left", }}>
                        <div><b>Schedule Parking Space Availability</b></div>
                        <div>Plan the days and hours when your parking spaces will be available for robo taxi bookings.</div>
                    </div>
                </div>
                 {/* Instructional Note; 266 */}
        <div style={{ marginTop: '10px', padding: '10px', backgroundColor: '#f9f9f9', border: '1px solid #ccc', borderRadius: '5px' }}>
            <Typography variant="body1" color="textSecondary">
                <b>Note:</b> Starting in July 2024, this interface will be upgraded to a calendar experience for better scheduling and management.
            </Typography>
        </div>
                <div className="row m-3" >
                    <div className="col-md-12">
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedValue} // Set the value of the RadioGroup
                            onChange={handleradiobutton}
                        >
                            <FormControlLabel
                                value="WholeWeek"
                                control={<Radio />}
                                label="Whole Week"
                                className="radiolabel"
                            />
                            <FormControlLabel
                                value="WeekDays"
                                control={<Radio />}
                                label="WeekDays"
                                className="radiolabel"
                            />
                            <FormControlLabel
                                value="WeekEnd"
                                control={<Radio />}
                                label="WeekEnd"
                                className="radiolabel"
                            />
                            <FormControlLabel
                                value="Custom"
                                control={<Radio />}
                                label="Custom"
                                className="radiolabel"
                            />
                       </RadioGroup>
                        <div>
                            <div className='scheduleavailability mb-1'>
                                <div className='days'>Monday:</div>
                                <div className='daystextfield'>
                                    <FormControl sx={{ m: 1, width: 250 }}
                                        value={timeValues.Monday}
                                    >
                                        <InputLabel htmlFor="monday-input"
                                            value={timeValues.Monday}
                                        >Provide Time</InputLabel>
                                        <OutlinedInput
                                            id="monday-input"
                                            data-testid="monday-input"
                                            value={timeValues.Monday}
                                            onChange={handleChange("Monday")}
                                            placeholder="Select Time"
                                            disabled={selectedValue === "WeekEnd"}
                                            style={{
                                                backgroundColor: selectedValue === "WeekEnd" ? '#d3d3d3' : 'inherit'
                                            }}
                                        />
                                        {errors['Monday'] && <span style={{ color: 'red' }}>{errors['Monday']}</span>}
                                    </FormControl>
                                </div>
                                <div className='days'>Saturday:</div>
                                <div className='daystextfield'>
                                    <FormControl sx={{ m: 1, width: 250 }}>
                                        <InputLabel htmlFor="saturday-input">Provide Time</InputLabel>
                                        <OutlinedInput
                                            id="saturday-input"
                                            data-testid="saturday-input"
                                            value={timeValues.Saturday}
                                            onChange={handleChange("Saturday")}
                                            placeholder="Select Time"
                                            disabled={selectedValue === "WeekDays"}
                                            style={{
                                                backgroundColor: selectedValue === "WeekDays" ? '#d3d3d3' : 'inherit'
                                            }}
                                        />
                                        {errors['Saturday'] && <span style={{ color: 'red' }}>{errors['Saturday']}</span>}
                                    </FormControl>
                                </div>
                            </div>
                            <div className='scheduleavailability mb-1'>
                                <div className='days'>Tuesday:</div>
                                <div className='daystextfield'>
                                    <FormControl sx={{ m: 1, width: 250 }}>
                                        <InputLabel htmlFor="tuesday-input">Provide Time</InputLabel>
                                        <OutlinedInput
                                            id="tuesday-input"
                                            data-testid="tuesday-input"
                                            value={timeValues.Tuesday}
                                            onChange={handleChange("Tuesday")}
                                            placeholder="Select Time"
                                            disabled={selectedValue === "WeekEnd"}
                                            style={{
                                                backgroundColor: selectedValue === "WeekEnd" ? '#d3d3d3' : 'inherit'
                                            }}
                                        />
                                        {errors['Tuesday'] && <span style={{ color: 'red' }}>{errors['Tuesday']}</span>}
                                    </FormControl>
                                </div>
                                <div className='days'>Sunday:</div>
                                <div className='daystextfield'>
                                    <FormControl sx={{ m: 1, width: 250 }}>
                                        <InputLabel htmlFor="sunday-input">Provide Time</InputLabel>
                                        <OutlinedInput
                                            id="sunday-input"
                                            data-testid="sunday-input"
                                            value={timeValues.Sunday}
                                            onChange={handleChange("Sunday")}
                                            placeholder="Select Time"
                                            disabled={selectedValue === "WeekDays"}
                                            style={{
                                                backgroundColor: selectedValue === "WeekDays" ? '#d3d3d3' : 'inherit'
                                            }}
                                        />
                                        {errors['Sunday'] && <span style={{ color: 'red' }}>{errors['Sunday']}</span>}
                                    </FormControl>
                                </div>
                            </div>

                            <div className='scheduleavailability mb-1'>
                                <div className='days' style={{ width: "200px" }}>Wednesday:</div>
                                <div className='daystextfield'>
                                    <FormControl sx={{ m: 1, width: 250 }}>
                                        <InputLabel htmlFor="wednesday-input">Provide Time</InputLabel>
                                        <OutlinedInput
                                            id="wednesday-input"
                                            data-testid="wednesday-input"
                                            value={timeValues.Wednesday}
                                            onChange={handleChange("Wednesday")}
                                            placeholder="Provide Time"
                                            disabled={selectedValue === "WeekEnd"}
                                            style={{
                                                backgroundColor: selectedValue === "WeekEnd" ? '#d3d3d3' : 'inherit'
                                            }}
                                        />
                                        {errors['Wednesday'] && <span style={{ color: 'red' }}>{errors['Wednesday']}</span>}
                                    </FormControl>
                                </div>
                                <div style={{right:"-1000px"}}> {errors.general && <span style={{ color: 'red' }}>{errors.general}</span>}</div>
                            </div>
                            <div className='scheduleavailability mb-1'>
                                <div className='days' style={{ width: "200px" }}>Thursday:</div>
                                <div className='daystextfield'>
                                    <FormControl sx={{ m: 1, width: 250 }}>
                                        <InputLabel htmlFor="thursday-input">Provide Time</InputLabel>
                                        <OutlinedInput
                                            id="thursday-input"
                                            data-testid="thursday-input"
                                            value={timeValues.Thursday}
                                            onChange={handleChange("Thursday")}
                                            placeholder="Provide Time"
                                            disabled={selectedValue === "WeekEnd"}
                                            style={{
                                                backgroundColor: selectedValue === "WeekEnd" ? '#d3d3d3' : 'inherit'
                                            }}
                                        />
                                        {errors['Thursday'] && <span style={{ color: 'red' }}>{errors['Thursday']}</span>}
                                    </FormControl>
                                </div>
                            </div>
                            <div className='scheduleavailability mb-1'>
                                <div className='days' style={{ width: "200px" }}>Friday:</div>
                                <div className='daystextfield'>
                                    <FormControl sx={{ m: 1, width: 250 }}>
                                        <InputLabel htmlFor="friday-input">Provide Time</InputLabel>
                                        <OutlinedInput
                                            id="friday-input"
                                            data-testid="friday-input"
                                            value={timeValues.Friday}
                                            onChange={handleChange("Friday")}
                                            placeholder="Provide Time"
                                            disabled={selectedValue === "WeekEnd"}
                                            style={{
                                                backgroundColor: selectedValue === "WeekEnd" ? '#d3d3d3' : 'inherit'
                                            }}
                                        />
                                        {errors['Friday'] && <span style={{ color: 'red' }}>{errors['Friday']}</span>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" mb-3" style={{ textAlign: "end" }}>
                <button type="submit" className='finishbtn' onClick={backButtonClick} style={{ marginRight: "10px" }}>Go Back</button>
                <button type="submit" className='nextBtn' onClick={handleButtonClick}>Next</button>
            </div>
        </div>
    )
}

export default ScheduleAvailability;
