import { useState, useCallback, useEffect, useRef } from 'react';
import Frame1 from "./Frame1";
import PortalPopup from "./PortalPopup";
import atlas from 'azure-maps-control';
import Frame from "./Frame";
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// import {AccordionDetails } from '@mui/joy';
import ScheduleAvailability from '../scheduleAvailability/scheduleAvailability';
import BankDetailsMain from '../bankDetails/bankDetailsMain'
import ReviewAndConfirm from '../reviewConfirm/reviewandConfirm';
import { GrFormSearch } from "react-icons/gr";
import MarkingAlert from '../NavBar/MarkingAlert';
import { AccordionGroup } from '@mui/joy';
import { decryptPassword } from '../utils/EncryptDecrypt';

import { Accordion, AccordionSummary, AccordionDetails,  Typography, IconButton } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon, Close as CloseIcon } from '@material-ui/icons';
const MapDashboard = (props) => {
	const mapRef = useRef(null);
	let map;
	let markersData = useRef([]);
	let markerValues = [];
	const [expanded, setExpanded] = useState(false);
	const hostPath = process.env.REACT_APP_API_BASE_URL;
	const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
	const [isFrameOpen, setFrameOpen] = useState(true);
	const [isFrame1Open, setFrame1Open] = useState(false);
	const [isAddressSelected, setConfirmAddress] = useState(false)
	const [isScheduleAvailabilityPopupConfirm, setConfirmforScheduleAvailability] = useState(true)
	const [searchValue, setSearchValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [searchError, setSearchError] = useState(false);
	const [issearchdisabled, setSearchdisabled] = useState(false);
	const [addressvalue, setAddressvalue] = useState('');
	const [selectedOption, setSelectedOption] = useState([]);
	const [areasMarked, setareasmarked] = useState([]);
	const [scheduleavailability, setscheduleavailability] = useState(false);
	const [selectedValue, setSelectedValue] = useState(''); // Set default to Custom
	const [bankaccountdetails, setbankaccountdetails] = useState({});
	const [alertpopup, setAlertpopup] = useState(false);
	const [optionerror, setOptionerror] = useState(false);
	const [expandedMark, setExpandedMark] = useState(false)
	const [exitAlert, setExitAlert] = useState(false);
	let mapMarkers = useRef([]);
	let currentMarker = useRef();
	const [timeValues, setTimeValues] = useState({
		Monday: "",
		Tuesday: "",
		Wednesday: "",
		Thursday: "",
		Friday: "",
		Saturday: "",
		Sunday: "",
	});
	const steps = [
		"1 of 5 - Locate your Property",
		"2 of 5 - Mark Pick up and Drop off Area",
		"3 of 5 - Schedule Availability",
		"4 of 5 - Enter Bank Details",
		"5 of 5 - Review and Confirm"
	];
	const [areas, setAreas] = useState({
		"PUE - Pick Up Entrance": 0,
		"DOE - Drop Off Entrance": 0,
		"PUL - Pick Up Location": 0,
		"DOL - Drop Off Location": 0,
		"PUX - Pick Up Exit": 0,
		"DOX - Drop Off Exit": 0,
		"PKE - Parking Entrance": 0,
		"PKX - Parking Exit": 0,
		"PSL - Parking Space Location": 0
	});
	const areaPair = {
		"PUE - Pick Up Entrance": "PUX - Pick Up Exit",
		"DOE - Drop Off Entrance": "DOX - Drop Off Exit",
		"PKE - Parking Entrance": "PKX - Parking Exit"
	}
	const [markingalert, setMarkingalert] = useState(false);
	const [conditions, setConditions] = useState({
		"1 of 5 - Locate your Property": true,
		"2 of 5 - Mark Pick up and Drop off Area": false,
		"3 of 5 - Schedule Availability": false,
		"4 of 5 - Enter Bank Details": false,
		"5 of 5 - Review and Confirm": false
	});
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [enableMarker, setEnablemarker] = useState(false);
	const [bankdetails, setBankdetails] = useState(false);
	const [reviewlocation, setReviewlocation] = useState(false);
	const [errors, setErrors] = useState({});
	const [defaultaddress, setDefaultaddress] = useState(localStorage.getItem('EnityMailingAdderess') || '');
	const [errorsbank, setbankErrors] = useState({});
	const [accountinfo, setAccountinfo] = useState({
		BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
		AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
		AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
		AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
		RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
		SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
	});
	function getLoggedInUser() {
		const loggedInUser = localStorage.getItem('LogedInUser');
		if (!loggedInUser) {
			console.error('No LoggedInUser found');
			return null;
		}
		try {
			const stateLoggedInUser = JSON.parse(loggedInUser);
			return stateLoggedInUser;
		} catch (error) {
			console.error('Error parsing LoggedInUser', error);
			return null;
		}
	}
	const stateLogedInUser = getLoggedInUser();
	const accordionStyles = {
		marginLeft: !scheduleavailability ? '0px' : '20px',
		flexGrow: !scheduleavailability ? 0 : 1
	};
	const mapStyles = {
		display: !scheduleavailability ? '' : 'flex'
	};
	const validateTimeValues = () => {
		const newErrors = {};
		switch (selectedValue) {
			case 'WeekDays':
				['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			case 'WeekEnd':
				['Saturday', 'Sunday'].forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			case 'Custom':
				if (!Object.values(timeValues).some(value => value)) {
					newErrors.general = "At least one day must have a value.";
				}
				break;
			case 'WholeWeek':
				Object.keys(timeValues).forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			default:
				break;
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};
	const handleInputChange = (account) => (e) => {
		const { name, value } = e.target;
		setAccountinfo({ ...accountinfo, [account]: value });
		const validateField = (name, value, regex, emptyMessage, invalidMessage) => {
			if (value.trim() === "") {
				setbankErrors(errors => ({ ...errors, [name]: emptyMessage }));
			} else if (regex && !regex.test(value)) {
				setbankErrors(errors => ({ ...errors, [name]: invalidMessage }));
			} else {
				setbankErrors(errors => ({ ...errors, [name]: "" }));
			}
		};
		switch (name) {
			case 'BankName':
				validateField(name, value, /^[a-zA-Z\s]*$/, 'Bank Name is required', 'Please enter alphabets only');
				break;
			case 'AccountHolderName':
				validateField(name, value, /^[a-zA-Z\s]*$/, 'Account Holder Name is required', 'Please enter alphabets only');
				break;
			case 'AccountType':
				validateField(name, value, /^[A-Za-z\s()]+$/, 'Account Type is required', 'Please Check the Account Type Format');
				break;
			case 'AccountNumber':
				validateField(name, value, /^\d{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits');
				break;
			case 'RoutingNumber':
				validateField(name, value, /^\d{9}$/, 'Routing Number is required', 'Routing Number must be exactly 9 digits');
				break;
			case 'SWIFT_BIC_Code':
				// Add validation if needed
				break;
			default:
				break;
		}
	};
	const handleCancelalert = (val) => {
		setMarkingalert(val);
	};
	
	const handleexitalert = (val, flag) => {
		if (!flag) {
			clearMarkers();
			InitMap();
		}
		setExitAlert(val);
		markersData.current = [];
		mapMarkers.current = [];
	};


	const resetStates = () => {
		setExpanded(false);
		setFrame1Open(false);
		setConfirmAddress(false);
		setConfirmforScheduleAvailability(true);
		setSearchValue('');
		setSuggestions([]);
		setSearchError(false);
		setSearchdisabled(false);
		setAddressvalue('');
		setSelectedOption([]);
		setareasmarked([]);
		setscheduleavailability(false);
		setSelectedValue('');
		setAlertpopup(false);
		setOptionerror(false);
		setTimeValues({
			Monday: "", Tuesday: "", Wednesday: "",
			Thursday: "", Friday: "", Saturday: "", Sunday: "",
		});
		setConditions({
			"1 of 5 - Locate your Property": true,
			"2 of 5 - Mark Pick up and Drop off Area": false,
			"3 of 5 - Schedule Availability": false,
			"4 of 5 - Enter Bank Details": false,
			"5 of 5 - Review and Confirm": false
		});
		setEnablemarker(false);
		setBankdetails(false);
		setReviewlocation(false);
		setErrors({});
		setbankErrors({});
		setMarkingalert(false);
		searchLocation(defaultaddress);
		setAreas({
			"PUE - Pick Up Entrance": 0,
			"DOE - Drop Off Entrance": 0,
			"PUL - Pick Up Location": 0,
			"DOL - Drop Off Location": 0,
			"PUX - Pick Up Exit": 0,
			"DOX - Drop Off Exit": 0,
			"PKE - Parking Entrance": 0,
			"PKX - Parking Exit": 0,
			"PSL - Parking Space Location": 0
		});
		let mapElement = document.getElementById('myMap');
		if ((mapElement)) {
			map = new atlas.Map('myMap', {
				center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
				zoom: 18,
				language: 'en-US',
				style: 'satellite',
				authOptions: {
					authType: 'subscriptionKey',
					subscriptionKey: 'iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw'
				}
			});
			mapRef.current = map;
		}

	};

	const handleDecrypt = (encryptedText) => {
		if (!encryptedText) {
			return ""; // Return empty string or handle as needed
		}
	
		try {
			const decrypted = decryptPassword(encryptedText);
			return decrypted;
		} catch (error) {
			return ""; // Adjust based on your error handling strategy
		}
	};


	const validateFields = () => {
		let tempErrors = {};
		let isValid = true;
		const validateField = (field, value, regex, emptyMessage, invalidMessage) => {
			if (value?.trim() === "" || value === null || value === undefined) {
				tempErrors[field] = emptyMessage;
				isValid = false;
			} else if (regex && !regex.test(value)) {
				tempErrors[field] = invalidMessage;
				isValid = false;
			}
		};
		validateField('BankName', accountinfo.BankName, /^[A-Za-z\s]+$/, 'Bank Name is required', 'Please enter alphabets only');
		validateField('AccountHolderName', accountinfo.AccountHolderName, /^[A-Za-z\s]+$/, 'Account Holder Name is required', 'Please enter alphabets only');
		const decryptedAccountNumber = bankdetails.status === false || !handleDecrypt(accountinfo.AccountNumber) ? accountinfo.AccountNumber : handleDecrypt(accountinfo.AccountNumber);
		const decryptedRoutingNumber = bankdetails.status === false || !handleDecrypt(accountinfo.RoutingNumber) ? accountinfo.RoutingNumber : handleDecrypt(accountinfo.RoutingNumber);
		validateField('AccountNumber', decryptedAccountNumber, /^\d{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits');
		validateField('RoutingNumber', decryptedRoutingNumber, /^\d{9}$/, 'Routing Number is required', 'Routing Number must be exactly 9 digits');
		validateField('AccountType', accountinfo.AccountType,/^[A-Za-z\s()]+$/, 'Account Type is required', 'Please Check the Account Type Format');
		setbankErrors(tempErrors);
		return isValid;
	};
	const handleReview = (val) => {
		setReviewlocation(val);
	}
	const timeSlotesAvailability = () => {
		if (validateTimeValues()) {
			setBankdetails(true);
			toggleCondition('4 of 5 - Enter Bank Details')
			toggleCondition('3 of 5 - Schedule Availability')
		}
	}
	useEffect(() => {
		setTimeValues({
			Monday: "",
			Tuesday: "",
			Wednesday: "",
			Thursday: "",
			Friday: "",
			Saturday: "",
			Sunday: "",
		});
		setErrors({});
	}, [selectedValue]);
	const handlebankdetails = (val) => {
		setBankdetails(val);
	}
	const handlebankaccountdetails = (val) => {
		setbankaccountdetails(val);
	}

	const handleChange = (day) => (event) => {
		const { value } = event.target;
		setTimeValues({ ...timeValues, [day]: value });
		const newErrors = { ...errors };
		delete newErrors.general;
		delete newErrors[day];
		setErrors(newErrors);

	};

	const findKeyByValue = (obj, valueToFind) => {
		for (const [key, val] of Object.entries(obj)) {
			if (val === valueToFind) {
				return key;
			}
		}
		return null;
	};
	const handleselectedOption = (area) => {
		currentMarker.current = area;
		const value = areaPair[area];
		const key = findKeyByValue(areaPair, area);
		if (value) {
			if (areas[value] > 0 && areas[area] === 0) {
				exitMarkers(value, area);
			}
		}
		else if (key) {
			if (areas[key] > 0 && areas[area] === 0) {
				exitMarkers(key, area);
			}

		}
		setSelectedOption(area);
		setEnablemarker(true);
		setOptionerror(false);

	}
	const openFrame = useCallback(() => {
		setFrameOpen(true);
	}, []);

	const closeFrame = () => {
		setFrameOpen(false);
	};

	const openFrame1 = useCallback(() => {

		if ((addressvalue || defaultaddress) && !isAddressSelected) {
			setFrame1Open(true);
			setConfirmAddress(true)
		}
		if (isAddressSelected) {
			if (selectedOption.length === 0 && issearchdisabled) {
				setOptionerror(true);
			}
			else if (selectedOption.length > 0 && areasMarked.length === 0) {
				handleCancelalert(true);
			}
			else {
				setFrame1Open(true);
			}
		}
		if (selectedOption.length > 0 && areasMarked.length > 0) {
			setConfirmforScheduleAvailability(true)
		}

	}, [addressvalue, isAddressSelected, selectedOption, issearchdisabled, areasMarked]);


	const closeFrame1 = useCallback(() => {
		setFrame1Open(false);
		setAlertpopup(false);
	}, []);

	const handleContinue = () => {
		if (selectedOption.length > 0) {
			setscheduleavailability(true)
			toggleCondition('2 of 5 - Mark Pick up and Drop off Area')
			toggleCondition('3 of 5 - Schedule Availability')
			setExpanded(true)
			closeFrame1();
		}
		else {
			setFrameOpen(true)
			setSearchdisabled(true);
			toggleCondition('1 of 5 - Locate your Property')
			toggleCondition('2 of 5 - Mark Pick up and Drop off Area')
			closeFrame1();
		}

	}
	const handleConfirm = (val) => {
		setscheduleavailability(val);
		setExpanded(false);

	}
	let markers = []; // Array to store all markers
	const selectedOptionRef = useRef(selectedOption);

	useEffect(() => {
		selectedOptionRef.current = selectedOption; // Update the ref value whenever selectedOption changes
	}, [selectedOption]);

	useEffect(() => {
		const initializeMap = () => {
			let mapElement = document.getElementById('myMap');
			if (mapElement) {
				map = new atlas.Map('myMap', {
					center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
					zoom: 18,
					language: 'en-US',
					style: 'satellite',
					authOptions: {
						authType: 'subscriptionKey',
						subscriptionKey: 'iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw'
					}
				});
				mapRef.current = map;
				map.events.add('ready', function () {
					const zoomControl = new atlas.control.ZoomControl();
					map.controls.add(zoomControl, {
						position: 'bottom-right'
					});
				});
			}
		};
	
		if (latitude && longitude) {
			initializeMap();
		}
	}, [latitude, longitude, scheduleavailability]);
	
	const exitMarkers = (val, area) => {
		areasMarked?.forEach(marker => {
			if (marker?.Coordinates?.Title === val) {
				const exitLatitude = marker?.Coordinates?.Latitude;
				const exitLongitude = marker?.Coordinates?.Longitude + 0.0001;
				const exitOptionText = area;
				const newId = Date.now();
				const newMarker = { latitude: exitLatitude, longitude: exitLongitude, optionText: exitOptionText, id: newId };
				if (checkMarkerExistance(exitLatitude, exitLongitude, exitOptionText)) {
					markersData?.current?.push(newMarker);
				}
			}
		});
		InitMap(markersData?.current);
	};

	const checkMarkerExistance = (lat, long, text) => {
		if (markerValues?.length === 0) return true;
		for (const marker of markerValues) {
			if (lat === marker.latitude && long === marker.longitude && marker.optionText === text) {
				return false;
			}
		}
		return true;
	};
	const clearMarkers = () => {
		const map = mapRef.current;
		mapMarkers?.current?.forEach(marker => {
			map.markers.remove(marker);
		});
		// Clear mapMarkers, markersData, and areasmarked arrays
		areasMarked.forEach(marker => {
			markersData?.current?.forEach(m => {
				if (m.latitude === marker.Coordinates.Latitude && m.longitude === marker.Coordinates.Longitude && m.optionText === marker.Name) {
					setareasmarked(prev => prev.filter(m1 => m1.id !== m.id));
				}
			})
		});
		// Reset areas to zero for all keys
		const updatedAreas = { ...areas };
		markersData?.current?.forEach(marker => {
			const key = marker.optionText;
			if (updatedAreas.hasOwnProperty(key)) {
				updatedAreas[key] = Math.max(0, updatedAreas[key] - 1); // Ensure count does not go below 0
			}
		});
		setAreas(updatedAreas);
		mapMarkers.current = [];
		markersData.current = [];
	};
	
	async function InitMap(markersData) {
		const map = mapRef.current;
        const createMarkerHTMLContent = (optionText, latitude, longitude) => {
			const text = typeof optionText === 'string' ? optionText : String(optionText);
			return `
				<div style="position: relative; width: 35px; height: 60px;">
					<svg xmlns="http://www.w3.org/2000/svg" width="35" height="60" viewBox="0 0 24 36" fill="#6ACD54">
						<path d="M12 0C5.383 0 0 5.383 0 12c0 7.188 10.83 22.812 10.83 22.812C11.147 34.81 12 34.365 12 34.365S12.853 34.81 13.17 34.812C13.17 34.812 24 19.188 24 12C24 5.383 18.617 0 12 0z"/>
						<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black" font-size="8">${text?.substring(0, 3)}</text>
					</svg>
					<div class="marker-card" style="position: absolute; top: -80px; left: 26.5px; padding: 15px; background: white; border-radius: 10px; width: 230px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); display: none; z-index: 100;">
						<div id="closeButton" style="position: absolute; top: 5px; right: 5px; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; color: black; cursor: pointer;">
							<img className="icround-gps-fixed-icon" alt="Delete" src="Delete.png" style="width: 100%; height: 100%;" />
						</div>					
						<div style="font-size: 12px; color: black; margin-bottom: 8px;">
						    ${text}<br />
							Latitude: ${latitude}<br />
							Longitude: ${longitude}
						</div>
					</div>
				</div>`;
		};

		

		const addMarker = (latitude, longitude, optionText, id) => {
			if (checkMarkerExistance(latitude, longitude, optionText)) {
				const marker = new atlas.HtmlMarker({
					draggable: true,
					position: [longitude, latitude],
					htmlContent: createMarkerHTMLContent(optionText, latitude, longitude)
				});

				if (checkMarkerExistance(latitude, longitude, optionText)) {
					map?.markers?.add(marker);
					if (markersData?.length > 0) {
						mapMarkers?.current?.push(marker); // Track the marker
					}
					markerValues?.push({ longitude: longitude, latitude: latitude, optionText: optionText });

				}

				const markerElement = marker?.getElement();
				const closeButton = markerElement?.querySelector('#closeButton');

				if (closeButton) {
					markerElement?.addEventListener('mouseover', () => {
						const card = markerElement.querySelector('.marker-card');
						if (card) {
							card.style.display = 'flex';
						}
					});

					markerElement.addEventListener('mouseout', () => {
						const card = markerElement.querySelector('.marker-card');
						if (card) {
							card.style.display = 'none';
						}
					});

					closeButton.addEventListener('click', function (e) {
						map.markers.remove(marker);
						setAreas(prevAreas => ({
							...prevAreas,
							[optionText]: prevAreas[optionText] - 1
						}));
						setareasmarked(prev => prev.filter(m => m.id !== id));
						e.stopPropagation();
					});
				}

				map?.events?.add('dragend', marker, function () {
					const newPosition = marker.getOptions().position;
					const newLatitude = newPosition[1];
					const newLongitude = newPosition[0];
					setareasmarked(prev => {
						return prev.map(m => {
							if (m.id === id) {
								return {
									...m,
									latandlong: `Latitude: ${newLatitude} Longitude: ${newLongitude}`,
									Coordinates: {
										...m.Coordinates,
										Latitude: newLatitude,
										Longitude: newLongitude
									}
								};
							}
							return m;
						});
					});
				});

				const markdata = {
					id,
					"Name": optionText,
					"latandlong": `Latitude: ${latitude} Longitude: ${longitude}`,
					"Coordinates": {
						"Title": optionText,
						"Latitude": latitude,
						"Longitude": longitude
					}
				};

				const markerExists = areasMarked.some(area => (area.id === id || (area.Coordinates.Latitude === latitude && area.Coordinates.Longitude === longitude)));
				if (!markerExists) {
					setareasmarked(prev => [...prev, markdata]);
					setAreas(prevAreas => ({
						...prevAreas,
						[optionText]: prevAreas[optionText] + 1
					}));

				}
			}
		};
		if (enableMarker) {
			if (map && map.events && map.events.add) {
				if (!map._listenersAdded) {
					map._listenersAdded = true;

					map.events.add('ready', function () {
						areasMarked.forEach(area => {
							const { Latitude, Longitude, Title, id } = area.Coordinates;
							addMarker(Latitude, Longitude, Title, area.id);
						});
					});

					map.events.add('click', function (e) {
						const clickedLatitude = e.position[1];
						const clickedLongitude = e.position[0];
						const newId = Date.now();
						addMarker(clickedLatitude, clickedLongitude, selectedOptionRef?.current, newId);
					});
				}
			}
		}
		markersData?.forEach(marker => {
			const { latitude, longitude, optionText, id } = marker;
			addMarker(latitude, longitude, optionText, id);
			setExitAlert(true);
		});

	}
	useEffect(() => {
		InitMap();
	}, [enableMarker, scheduleavailability]);

	useEffect(() => {
		// Update all markers' text when selectedOption changes
		markers.forEach(marker => {
			if (marker._textElement) {
				marker._textElement.textContent = selectedOption?.substring(0, 3);
			}
		});
	}, [selectedOption]);
	const searchLocation = (locationName) => {
		var data = []
		data.location = locationName
		axios.get(`https://atlas.microsoft.com/search/address/json?&subscription-key=iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw&api-version=1.0&language=en-US&query=${locationName}`)
			.then((response) => {
				const results = response.data.results;
				if (results && results.length > 0) {
					const firstResult = results[0];
					const { position } = firstResult;
					setLatitude(position.lat);
					setLongitude(position.lon);
					const bounds = [];
					// InitMap();
				}
			})
			.catch((error) => {
				console.error('Error fetching location data:', error);
			});
	};

	useEffect(() => {
		if (defaultaddress.trim() !== '') {
			searchLocation(defaultaddress);
		}
	}, [defaultaddress]);
	const getDataFromAPI = (value) => {
		if (value) {
			const locationName = value.trim();
			if (locationName !== '') {
				setAddressvalue(locationName);
				setTimeout(() => {
					searchLocation(locationName, map);
				}, 1000);
			}

		}
		else {
			setAddressvalue('');
		}
	};
	useEffect(() => {
		if (searchValue?.trim() !== '') {
			// Fetch suggestions from API
			axios.get(`https://atlas.microsoft.com/search/fuzzy/json?&subscription-key=iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw&api-version=1.0&language=en-US&query=${searchValue}&filter=countryCode=US`)
				.then(response => {
					const results = response.data.results;
					if (results && results.length > 0) {
						const suggestedLocations = results?.filter(result => result.address.countryCode === 'US').map(result => result.address.freeformAddress);
						setSuggestions(suggestedLocations);
					}
				})
				.catch(error => {
					console.error('Error fetching location suggestions:', error);
				});
		} else {
			setAddressvalue('');
			setSuggestions([]);

		}
	}, [searchValue]);

	const handleSearchChange = (value) => {
		const invalidCharacters = /[^a-zA-Z0-9\s,]/;
		if (invalidCharacters.test(value)) {
			setSearchError(true);
		} else {
			setSearchError(false);
		}
		setSearchValue(value);
	};

	const handleradiobutton = (event) => {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue);
	};
	useEffect(() => {
		setAccountinfo({
			BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
			AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
			AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
			AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
			RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
			SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
			Add_Ass_with_Account: bankaccountdetails ? bankaccountdetails.add_Ass_with_Account : "",
		});
	}, [bankaccountdetails]);
	useEffect(() => {
		const emailId = {
			"EmailId": stateLogedInUser
		}
		const requestOptions = {
			method: 'POST',
			headers: { "x-functions-key": `${hostHeader}` },
			body: emailId
		};
		axios.post(`${hostPath}/api/GetBankDetails`, requestOptions.body, { headers: requestOptions.headers })
			.then(response => {
				if (response.data) {
					const bankdetails = response.data;
					setbankaccountdetails(bankdetails);
				}
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [])
	const handleAccord = () => {
		setExpanded(!expanded);
	}	
	const handleMarkerAccord = () => {
		setExpandedMark(!expandedMark);
	}
	const toggleCondition = (key) => {
		setConditions((prevConditions) => ({
			...prevConditions,
			[key]: !prevConditions[key],
		}));
	};
	const handleSearchclick = () => {
		setFrame1Open(true);
		setAlertpopup(true);
	}
	const handleAddressreset = () => {
		resetStates();
	}

	const handleChangeAccordian = () => {
		setExpanded(!expanded);
	};
	const handleAccordMark = () => {
		setExpandedMark(!expandedMark);
	}
	return (
		<>
			<div className="map" style={{ width: '100%', height: '910px', overflow: 'hidden' }}>
				{/* Flex container for accordion and schedule availability */}
				<div className="flex-container" style={mapStyles}>
				<AccordionSummary
					expanded={expanded} onChange={handleChangeAccordian}
					disableDivider sx={{ maxWidth: 400 }} style={{ zIndex: 1000, position: 'relative', alignItems: "center", Padding: "50px", maxWidth:"500px" }} className="vector-parent">
						<Accordion expanded={expanded} onChange={handleAccord} >
							<AccordionSummary
							expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							>Property Registration Steps </AccordionSummary>
							{steps.map((step, index) => (
								<AccordionDetails
									key={step}
									className={`AccordName ${conditions[step] ? 'selected' : ''}`}>
									{step}
								</AccordionDetails>
							))}
						</Accordion>
					</AccordionSummary>
					<div style={accordionStyles}>
						{!scheduleavailability ? (
							<div>
								<div className="map-child" id="myMap" style={{ width: '100%', height: "980px" }}></div>
								<div className="material-symbolsquestion-mark-wrapper" onClick={openFrame}>
									<img className="material-symbolsquestion-mark-icon" alt="" src="question-mark.png" />
								</div>
								<div className="next-wrapper" onClick={openFrame1}>
									<b className="next">Next</b>
								</div>
								{isAddressSelected && issearchdisabled && (
									<div className="back-wrapper" onClick={handleSearchclick}>
										<b className="next">Back</b>
									</div>)}
								<div className="component-1">
									<div style={{  flexShrink: "1", display: "flex" }}>
										{!issearchdisabled ? (
											<div className="enter-your-address">
												<Autocomplete
													style={{ width: 300, backgroundColor: "white", marginLeft: "-0%", borderRadius: "10px", flexShrink: "1" }}
													freeSolo
													autoComplete
													autoHighlight
													options={suggestions}
													getOptionLabel={(suggestions) => suggestions}
													filterOptions={(suggestions) => suggestions}
													onChange={(event, value) => getDataFromAPI(value)}
													onInputChange={(event, value) => setAddressvalue(value)}
													disabled={issearchdisabled}
													value={addressvalue}
													renderInput={(params) => (
														<TextField
															{...params}
															onChange={(e) => { handleSearchChange(e.target.value); }}
															variant="filled"
															label="Search Box"
															disabled={issearchdisabled}
															value={addressvalue}
														/>
													)}
												/>
												{searchError && (
													<div style={{ color: 'red', marginTop: '8px' }}>
														Enter alphabetic characters only
													</div>
												)}
											</div>) : <GrFormSearch onClick={handleSearchclick} style={{ backgroundColor: 'white', height: '50px', width: '50px', borderRadius: "10px", marginTop:"13px" }} />}
										{isAddressSelected && issearchdisabled ?
											<div className="enter-your-address">
												<AccordionSummary
					                            expanded={expandedMark} onChange={handleAccordMark}
												disableDivider sx={{ maxWidth: 500 }} style={{ zIndex: 1000, position: 'relative', alignItems: "center", Padding: "10px", }} className='vector-child '>
													<Accordion  expanded={expandedMark} onChange={handleMarkerAccord}>
														<AccordionSummary 
														expandIcon={expandedMark ? <RemoveIcon /> : <AddIcon />}
														aria-controls="panel1a-content"
														id="panel1a-header">Points to Mark on the Map
														</AccordionSummary>
															{Object.entries(areas).map(([key, value]) => (
															<AccordionDetails
																key={key}
																className={`AccordName1 ${selectedOption === key ? 'selected' : ''}`}
																onClick={() =>
																	handleselectedOption(key)}
															>
																{value}{" " + " "}{key}
															</AccordionDetails>
														))}
													</Accordion>
												</AccordionSummary>
												{optionerror && (
													<div style={{ color: 'red', marginTop: '8px' }}>
														Select option to mark on the map
													</div>
												)}
											</div>
											: ""}
									</div>
								</div>
							</div>
						) : (
							!bankdetails ? (
								<ScheduleAvailability
									handleConfirm={handleConfirm}
									handlebankdetails={handlebankdetails}
									handlebankaccountdetails={handlebankaccountdetails}
									handleradiobutton={handleradiobutton}
									selectedValue={selectedValue}
									errors={errors}
									timeValues={timeValues}
									handleChange={handleChange}
									timeSlotesAvailability={timeSlotesAvailability}
									EmailId={stateLogedInUser}
									toggleCondition={toggleCondition}
								/>
							) : (
								!reviewlocation ? (
									<BankDetailsMain handlebankdetails={handlebankdetails} bankdetails={bankaccountdetails}
										handleReview={handleReview} validateFields={validateFields} handleInputChange={handleInputChange}
										errors={errorsbank} accountinfo={accountinfo} toggleCondition={toggleCondition}
									/>) : (
									<ReviewAndConfirm handleReview={handleReview} areasMarked={areasMarked} addressvalue={addressvalue}
										accountinfo={accountinfo}
										bankdetails={accountinfo}
										EmailId={stateLogedInUser}
										latitude={latitude} longitude={longitude} toggleCondition={toggleCondition}
										handleviewparking={props.handleviewparking} defaultaddress={defaultaddress}
									/>
								)
							)
						)}
					</div>
				</div>
			</div>
			{isFrameOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeFrame}
				>
					<Frame1 isFrameOpen={isFrameOpen} onClose={closeFrame} enableMarker={enableMarker} isScheduleAvailabilityPopupConfirm={isScheduleAvailabilityPopupConfirm} isAddressSelected={isAddressSelected} />
				</PortalPopup>
			)}
			{isFrame1Open && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeFrame1}
				>
					<Frame isFrame1Open={isFrame1Open} onClose={closeFrame1} handleContinue={handleContinue} isScheduleAvailabilityPopupConfirm={isScheduleAvailabilityPopupConfirm} isAddressSelected={isAddressSelected}
						alertpopup={alertpopup} handleSearchclick={handleSearchclick} handleAddressreset={handleAddressreset} areasMarked={areasMarked}
					/>
				</PortalPopup>
			)}
			{(markingalert || exitAlert) && (<MarkingAlert markingalert={markingalert} handleCancelalert={handleCancelalert} exitAlert={exitAlert} handleexitalert={handleexitalert}
				currentMarker={currentMarker.current} markersDatalen={markersData.current.length}
			/>)}
		</>
	);
};

export default MapDashboard;