import { useCallback } from 'react';
import PropTypes from "prop-types";

const Frame = (props) => {
    const { className = '', overrides, isFrame1Open, onClose, handleContinue, handleSearchclick, alertpopup,handleAddressreset,areasMarked, ...rest } = props;
    if (!isFrame1Open) {
        return null;
    }

    return (
        <div className={`confirm-property-location-parent ${className}`}>
            {props.isAddressSelected && !alertpopup && areasMarked.length === 0? (
                <div>
                    <b className="confirm-property-location">Confirm Property Location</b>
                    <div className="before-proceeding-please">Before proceeding, please ensure that the property you have located on the map is correct.</div>
                    <div className="are-you-sure">Are you sure you want to go to the next step?</div>
                    <div className="yes-continue-wrapper" onClick={handleContinue}>
                        <b className="yes-continue">Yes, Continue</b>
                    </div>
                    <div className="no-go-back-wrapper" onClick={onClose}>
                        <b className="yes-continue">No, Go Back</b>
                    </div>
                </div>
            ) : (
                alertpopup ? (
                    <div>
                        <b className="confirm-property-location1">Are you sure you want change the address? </b>
                        <div className="yes-continue-wrapper" onClick={handleAddressreset}>
                            <b className="yes-continue">Yes, Go Back</b>
                        </div>
                        <div className="no-go-back-wrapper" onClick={onClose}>
                            <b className="yes-continue">No, Stay Here</b>
                        </div>
                    </div>
                ) : (
                    <div>
                        <b className="confirm-property-location">Are you sure you want to proceed ? </b>
                        <div className="before-proceeding-please">Don't worry! You can change the parking lot markers at any time from your dashboard.</div>
                        {/* <div className="are-you-sure">Are you sure you want to go to the next step?</div> */}
                        <div className="yes-continue-wrapper" onClick={handleContinue}>
                            <b className="yes-continue">Yes, Continue</b>
                        </div>
                        <div className="no-go-back-wrapper" onClick={onClose}>
                            <b className="yes-continue">No, Go Back</b>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

Frame.propTypes = {
    className: PropTypes.string,
};

export default Frame;
