import React from 'react';
import '../styles/Header.css';
import logo from '../assets/GeoParking.png';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleSignUpClick = (event) => {
    if (event.button === 0) {
      navigate('/signup');
    }
  };

  const handleLoginClick = (event) => {
    if (event.button === 0) {
      navigate('/login');
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="GeoParking Technologies" />
      </div>
      <nav>
        <ul className="nav-links">
          <li>
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li>
            <Link to="who-are-we" smooth={true} duration={500}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="contact-us" smooth={true} duration={500}>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
      <div className="auth-buttons">
        <a href="/signup" target="_blank" className="sign-up" onMouseDown={handleSignUpClick}>Sign Up</a>
        <a href="/login" target="_blank" className="login-1" onMouseDown={handleLoginClick}>Login</a>
      </div>
    </header>
  );
};

export default Header;
