import React, { useEffect,useState } from 'react';
import DashBoardHolder from '../../Images/dashboardPlaceholder.svg';
import Navbar from '../NavBar/Navbar';
import MyPropertiesHome from '../myproporties/myProperties_Home'
import MapDashboard from '../myproporties/MapDashboard';
const Dashboard = () => {
    const firstName = JSON.parse(localStorage.getItem('firstName'));
    const[isdashboard,setDashboard] = useState(true);
    const [mapvisible, setMapvisible] = useState(false);
    const RegisterProperty = (e) => {
        e.preventDefault();
        setDashboard(false);
    }
    const handleMapvisible = (val) =>{
		setMapvisible(val);
	}

    return (
        <div>
        	<Navbar mapvisible={mapvisible}/>
            {isdashboard ? (
            <div className="container-fluid p-0">
                <style>
                    {`
                    .container-fluid {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 90vh;
                        background-color: #f5f5f5;
                    }

                    .DashBoardBody {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #fff;
                        padding: 20px;
                        border-radius: 10px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    }

                    .dashboardheader {
                        font-size: 24px;
                        font-weight: bold;
                        text-align: center;
                        margin-bottom: 10px;
                    }

                    .we-re-thrilled-to {
                        font-size: 16px;
                        text-align: center;
                        margin-bottom: 20px;
                    }

                    .frame {
                        display: flex;
                        justify-content: center;
                    }

                    .reg-property {
                        padding: 18px 20px;
                        font-size: 16px;
                        border: none;
                        border-radius: 5px;
                        background-color: #007bff;
                        color: #fff;
                        cursor: pointer;
                    }

                    .illustration-auntum {
                        margin-bottom: 20px;
                    }

                    .clip-path-group {
                        max-width: 100%;
                        height: auto;
                    }
                `}
                </style>
                <div className="dashboard">
                    <div className="rectangle" />
                    <div className="rectangle-2" />
                </div>
                <div className="container-fluid DashBoardBody">
                    <div className="illustration-auntum">
                        <img className="clip-path-group" alt="Clip path group" src={DashBoardHolder} />
                    </div>
                    <div>
                        <p className="dashboardheader">Welcome {firstName ? firstName : "User"} to Your GeoParking Dashboard!</p>
                        <p className="we-re-thrilled-to">
                            We're thrilled to have you on board. With GeoParking, managing your parking lots has never been easier. Let's maximize your potential together!
                        </p>
                    </div>
                    <div className="frame">
                        <button className='reg-property' onClick={RegisterProperty}>Register Property</button>
                    </div>
                </div>
            </div>
            ):(
                !mapvisible?(
                <MyPropertiesHome handleMapvisible={handleMapvisible} mapvisible={mapvisible} isdashboard={isdashboard}/>):(
                    <MapDashboard/>
                )
            )}
        </div>
    );
};

export default Dashboard;
