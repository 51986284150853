import React, { useState, useEffect } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { useNavigate, Link, json } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const EntityInformation = (props) => {
    const { overrides, setIsFlagSet, isFlagSet, formData, setFormData, ...rest } = props;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [userData, setdata] = useState({});
    const [rangeValue, setRangeValue] = useState(30);
    const [postId, setPostId] = useState(null);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [emailrequestData, setEmailData] = useState({});
    const [phoneData, setPhoneData] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [addressdetails, setAddress] = useState();
    const [EmaiErr, setEmaiErr] = useState(false);
    const [displayValue, setDisplayValue] = useState("+1 ");
    const [displayValueOffice, setDisplayValueOffice] = useState("+1 ");
    const [mailingAddressZipCode, setMailingAddressZipCode] = useState("");
    const [zipcodeWarning, setZipcodeWarning] = useState("");
    const [mailingAddressCity, setMailingAddressCity] = useState("");

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get(
                    `https://api.countrystatecity.in/v1/countries/US/states`,
                    {
                        headers: {
                            "X-CSCAPI-KEY":
                                "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                        },
                    }
                );
                var data = response.data.filter((country) =>
                    /^[a-zA-Z0-9]+$/.test(country.iso2)
                );
                // Sort the states alphabetically by name
                data.sort((a, b) => a.name.localeCompare(b.name));
                setStates(data);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };

        fetchStates();
    }, []);

    useEffect(() => {
        if (searchValue?.trim() !== "") {
            // Fetch suggestions from API
            axios
                .get(
                    `https://atlas.microsoft.com/search/address/json?&subscription-key=iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw&api-version=1.0&language=en-US&query=${searchValue}&filter=countryCode=US`
                )
                .then((response) => {
                    const results = response.data.results;
                    if (results && results.length > 0) {
                        // Extract suggestion labels from response and update state
                        const suggestedLocations = results
                            .filter((result) => result.address.countryCode === "US")
                            .map((result) => result.address);
                        setSuggestions(suggestedLocations);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching location suggestions:", error);
                });
        } else {
            setSuggestions([]);
        }
    }, [searchValue]);

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        if (name == "EnityMailingAdderess") {
            if (name) {
                errors.EnityMailingAdderess = "";
                setFormData({ ...formData, [name]: value });
            } else {
                errors.EnityMailingAdderess = "Mailing Address is required";
            }
        }
        setSearchValue(e.target.value);
    };

    const handleAddressSelect = (address) => {
        const selectedState = states.find(
            (state) =>
                state.name.toLowerCase() ===
                address?.countrySubdivisionName?.toLowerCase()
        );
        var citylist;
        var selectedCity;
        setMailingAddressZipCode(address?.postalCode);
        setMailingAddressCity(address?.municipality);
        errors.CityId = "";
        errors.Zipcode="";
        setZipcodeWarning("");
        if (selectedState) {
            fetchCities(selectedState.iso2).then((citylist) => {
                selectedCity = citylist?.find(
                    (city) =>
                        city.name.toLowerCase() === address?.municipality?.toLowerCase()
                );
                setFormData((prevState) => ({
                    ...prevState,
                    CityId: selectedCity ? selectedCity.name : "",
                }));
            });
        }
        // Split the address by comma and select the first part
        const shortAddress = address?.freeformAddress.split(',')[0];
        setFormData((prevState) => ({
            ...prevState,
            EnityMailingAdderess: shortAddress, // Update with the short address
            Zipcode: address?.postalCode,
            StateId: selectedState ? selectedState.iso2 : "",
        }));
        setSearchValue("");
        setSuggestions([]);
    };

    const handleContinue = (e) => {
        e.preventDefault();
        if(!props.settingpage){
        console.log("e.prevebt default");
            setOpen(false);
            setEmailData({ EmailId: formData?.EmailId });
            props.sendOTPByEmail({ EmailId: props.formData?.EmailId });
            }
    };

    const fetchCities = async (data) => {
        try {
            const response = await axios.get(
                `https://api.countrystatecity.in/v1/countries/US/states/` +
                data +
                `/cities`,
                {
                    headers: {
                        "X-CSCAPI-KEY":
                            "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                    },
                }
            );
            setCities(response.data);
            return response.data;
        } catch (error) {
            return [];
            console.error("Error fetching cities:", error);
        }
    };

    const validateForm1 = () => {
        const errors = {};
        const alphabetRegex = /^[A-Za-z\s]*$/;
        const alphaNumericRegex = /^[a-zA-Z0-9\s]+$/;
        const digitRegex = /^\d*$/;

        if (!formData.EntityName) {
            errors.EntityName = "Entity Name is required";
        } else if (!alphaNumericRegex.test(formData.EntityName)) {
            errors.EntityName = "Entity Name cannot contain special characters";
        }

        if (!formData.EnityMailingAdderess) {
            errors.EnityMailingAdderess = "Mailing Address is required";
        }
        if (!formData.Country) {
            errors.Country = "Country is required";
        }
        if (!formData.StateId) {
            errors.StateId = "State is required";
        }
        if (!formData.CityId) {
            errors.CityId = "City is required";
        }
        if(formData.CityId !== mailingAddressCity){
            errors.CityId = "City does not match the address provided";
        }
        if (!formData.Zipcode) {
            errors.Zipcode = "ZIP Code is required";
        } else if (!digitRegex.test(formData.Zipcode)) {
            errors.Zipcode = "ZIP Code must contain only digits";
        } else if (formData.Zipcode.length > 5 || formData.Zipcode.length < 5) {
            errors.Zipcode = "ZIP Code must be 5 digits long";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const validateForm2 = () => {
        const errors = {};
        const alphabetRegex = /^[A-Za-z\s\'']*$/;

        if (!formData.FirstName) {
            errors.FirstName = "First Name is required";
        } else if (!alphabetRegex.test(formData.FirstName)) {
            errors.FirstName = "First Name must contain only alphabets";
        }

        if (!formData.LastName) {
            errors.LastName = "Last Name is required";
        } else if (!alphabetRegex.test(formData.LastName)) {
            errors.LastName = "Last Name must contain only alphabets";
        }

        if (!formData.title) {
            errors.title = "Title is required";
        } else if (!alphabetRegex.test(formData.title)) {
            errors.title = "Title must contain only alphabets";
        }

        if (!formData.EmailId) {
            errors.EmailId = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.EmailId)) {
            errors.EmailId = "Email is invalid";
        }
        if (EmaiErr) {
            errors.EmailId = "Email already exists";
        }
        if (!formData.MobileNumber) {
            errors.MobileNumber = "Mobile Number is required";
        } else {
            const phoneNumber = formData.MobileNumber.replace(/\D/g, "");
            if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
                errors.MobileNumber = "Mobile number is invalid";
            }
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const backgroundStyle = {
        background: `linear-gradient(90deg, green ${rangeValue}%, rgb(230 224 224) )`,
    };
    const handleButtonClick = (e) => {
        e.preventDefault();
        if (validateForm1()) {
            setIsFlagSet(true);
            setRangeValue(60);
        }
    };

    const goback = (e) => {
        e.preventDefault();
        setIsFlagSet(false);
        setRangeValue(30);
    };

    const registrationButtonClick = (e) => {
        e.preventDefault();
        if (validateForm2()) {
            try {
                if (errors.EmailId) {
                    setErrors({ ...errors, EmailId: "Email already exists" });
                } else {
                    // localStorage.setItem("formData", JSON.stringify(formData));
                    setOpen(true);
                }
            } catch (error) {
                console.error("Error checking email existence:", error.message);
                setErrors({ ...errors, EmailId: "Email already exists" });
            }
        }
    };

    async function checkEmailExistence(email) {
        setLoading(true);
        const emailid = { EmailId: email };
        const requestOptions = {
            method: "POST",
            headers: {
                "x-functions-key":
                   `${hostHeader}`
            },
            body: JSON.stringify(emailid),
        };

        try {
            const response = await axios.post(
                `${hostPath}/api/CheckEmailIdExist`,
                requestOptions.body,
                { headers: requestOptions.headers }
            );

            if (response.data === true) {
                setEmaiErr(true)
                const newErrors = { ...errors, EmailId: "Email already exists" };
                setErrors(newErrors);
                return Object.keys(newErrors).length === 0;
            } else {
                setEmaiErr(false)
                const newErrors = { ...errors, EmailId: "" };
                setErrors(newErrors);
                return Object.keys(newErrors).length === 0;

            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false); // Set loading state back to false after receiving the response
        }
    }

    useEffect(() => {
        if (email) {
            checkEmailExistence(email);
        }
    }, [email]);


    const formatPhoneNumber = (rawValue) => {
        let formattedValue = "+1 ";
        if (rawValue.length > 2) {
            formattedValue += `(${rawValue.slice(2, 5)})`;
        }
        if (rawValue.length >= 5) {
            formattedValue += `-${rawValue.slice(5, 8)}`;
        }
        if (rawValue.length >= 8) {
            formattedValue += `-${rawValue.slice(8, 12)}`;
        }
        return formattedValue;
    };

    useEffect(() => {
        // Set initial value to "+1 " if not already set
        if (!formData.MobileNumber) {
            setFormData({ ...formData, MobileNumber: "+1" });
        } else {
            setDisplayValue(formatPhoneNumber(formData.MobileNumber));
        }
    }, []);

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        const alphabetRegex = /^[A-Za-z\s\'']*$/;
        const alphaNumericRegex = /^[a-zA-Z0-9\s]+$/;
        const digitRegex = /^\d*$/;

        if (name === "EntityName") {
            if (!alphaNumericRegex.test(value) && value !== "") {
                errors.EntityName = "Entity Name cannot contain special characters";
            } else {
                errors.EntityName = "";
            }
            setFormData({ ...formData, [name]: value });
        }
        if (name === "Zipcode") {
            if (!digitRegex.test(value)) {
                errors.Zipcode = "ZIP Code must contain only digits";
            } else if ((value.length > 5 || value.length < 5) && value !== "") {
                errors.Zipcode = "ZIP Code must be 5 digits long";
            } else if (mailingAddressZipCode !== value && mailingAddressZipCode !== "" && value !== "") {
            setZipcodeWarning("Zipcode does not match the address provided");
            errors.Zipcode= "";
            }
            else {
                errors.Zipcode = "";
                setZipcodeWarning("");
            }
            setFormData({ ...formData, [name]: value });
        }

        if (name === "FirstName") {
            if (!alphabetRegex.test(value)) {
                errors.FirstName = "First Name must contain only alphabets";
            } else {
                errors.FirstName = "";
                setFormData({ ...formData, [name]: value });
            }
        }

        if (name === "LastName") {
            if (!alphabetRegex.test(value)) {
                errors.LastName = "Last Name must contain only alphabets";
            } else {
                errors.LastName = "";
                setFormData({ ...formData, [name]: value });
            }
        }
        if (name === "title") {
            if (!alphabetRegex.test(value)) {
                errors.title = "Title must contain only alphabets";
            } else {
                errors.title = "";
                setFormData({ ...formData, [name]: value });
            }
        }

        if (name === "EmailId") {
            setEmail((prev) => value);
            const emailid = { EmailId: value };
            const requestOptions = {
                method: "POST",
                headers: {
                    "x-functions-key":
                       `${hostHeader}`
                },
                body: JSON.stringify(emailid),
            };

            try {
                const response = axios.post(
                    `${hostPath}/api/CheckEmailIdExist`,
                    requestOptions.body,
                    { headers: requestOptions.headers }
                );

                if (response.data === true) {
                    errors.EmailId = "Email already exists";
                } else {
                    setFormData({ ...formData, [name]: value });
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }

        if (name === "MobileNumber") {
            let rawValue = value.replace(/[^+\d]/g, "");
            if (!rawValue.startsWith("+1")) {
                rawValue = "+1" + rawValue.replace(/^\+?1/, "");
            }
            if (rawValue.length > 12) {
                rawValue = rawValue.slice(0, 12);
            }
            let formattedValue = formatPhoneNumber(rawValue);
            if (rawValue.length !== 12) {
                setErrors({ ...errors, MobileNumber: "Mobile number is invalid" });
            } else {
                setErrors({ ...errors, MobileNumber: "" });
            }

            console.log("formattedValue", formattedValue);
            console.log("rawValue", rawValue);
            // Update the form data (store the raw value)
            setFormData({ ...formData, [name]: rawValue });
            setDisplayValue(formattedValue);
        }
        
        else if (name === "officePhone") {
            let rawValue = value.replace(/[^+\d]/g, "");
            if (!rawValue.startsWith("+1")) {
                rawValue = "+1" + rawValue.replace(/^\+?1/, "");
            }
            if (rawValue.length > 12) {
                rawValue = rawValue.slice(0, 12);
            }
            let formattedValueOffice = formatPhoneNumber(rawValue);
            if (rawValue.length !== 12) {
                setErrors({ ...errors, officePhone: "Office number is invalid" });
            } else {
                setErrors({ ...errors, officePhone: "" });
            }
        
            setFormData((prevState) => ({
                ...prevState,
                [name]: rawValue,
                setDisplayValueOffice: rawValue,
            }));
            setDisplayValueOffice(formattedValueOffice);
        } 
        else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === '+') {
            e.preventDefault();
            return;
        }
        if (e.key === "Backspace") {
            e.preventDefault();
            let { value } = e.target;
            // Check if value is more than "+1" to allow backspace
            if (value.length > 2) {
                value = value.slice(0, -1);
                setDisplayValue(value);
                let rawValue = value.replace(/[^+\d]/g, "");
    
                // Ensure +1 is always present
                if (!rawValue.startsWith("+1")) {
                    rawValue = "+1" + rawValue.slice(rawValue.startsWith("+") ? 1 : 0);
                }
                setFormData({ ...formData, MobileNumber: rawValue });
            } else {
                // If value is "+1", reset to just "+1" to prevent removing the prefix
                setDisplayValue("+1");
                setFormData({ ...formData, MobileNumber: "+1" });
            }
        }
    };

    const handleOfficeKeyDown = (e) => {
        if (e.key === '+') {
            e.preventDefault();
            return;
        }
        if (e.key === "Backspace") {
            e.preventDefault();
            let { value } = e.target;
            // Check if value is more than "+1" to allow backspace
            if (value.length > 2) {
                value = value.slice(0, -1);
                setDisplayValueOffice(value); 
                let rawValue = value.replace(/[^+\d]/g, "");
                if (!rawValue.startsWith("+1")) {
                    rawValue = "+1" + rawValue.slice(rawValue.startsWith("+") ? 1 : 0);
                }
                setFormData({ ...formData, OfficePhone: rawValue }); 
            } else {
                // If value is "+1", reset to just "+1" to prevent removing the prefix
                setDisplayValueOffice("+1");
                setFormData({ ...formData, OfficePhone: "+1" });
            }
        }
    };

    const stateChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            fetchCities(value);
        }
        if (name === 'StateId') {
            setFormData({ ...formData, [name]: value });
            errors.StateId = '';
        }
        else {
            errors.StateId = 'State is required';
        }
    };
    const cityChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            fetchCities(value);
        }
        if (name === 'CityId') {
            let errorMessage = '';
            if (value && value !== mailingAddressCity) {
                errorMessage = "City does not match the address provided";
            }
            setFormData({ ...formData, [name]: value });
            errors.CityId = errorMessage;
        } else {
            errors.CityId = 'City is required';
        }
    };
    const handleradiobutton = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value === 'email') {
            console.log('event.target.email', event.target.value);
        } else if (event.target.value === 'phone') {
            console.log('event.target.phone', event.target.value);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedValue("");
    };

    return (
        <div className="container-fluid p-0 entity">
            <div className="row m-0">
                <div className="col-md-6 p-0">
                    <img
                        src={RobotaxiLogin}
                        alt="Login"
                        style={{ width: "100%", height: "100vh" }}
                    />
                </div>
                <div className="col-md-6 p-0 mb-3">
                    <div className="contain">
                        <div className="registersteps"> Step {isFlagSet ? 2 : 1} of 3</div>
                        <input
                            type="range"
                            className="range-input mb-3"
                            value={rangeValue}
                            min="0"
                            max="100"
                            style={{
                                width: "80%",
                                height: "5px",
                                padding: "0",
                                borderRadius: "5.5px",
                                background: `linear-gradient(to right, green ${rangeValue}%, lightgray ${rangeValue}%)`,
                                outline: "none",
                            }}
                        />

                        <div className="login-form">
                            <div className="login">
                                <h3 className="loginheader textleft">Entity Information</h3>
                                <p className="mb-4 loginDescription textleft">
                                    Please provide the Parking Lot(s) owner/management entity
                                    information
                                </p>
                            </div>

                            {isFlagSet ? (
                                <form onSubmit={registrationButtonClick}>
                                    <div className="Username mb-2 textleft">
                                        <label
                                            htmlFor="first-name"
                                            className="userName loginlabel "
                                        >
                                            First Name
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.FirstName ? "input-active" : ""
                                                }`}
                                            id="first-name"
                                            maxLength={50}
                                            placeholder="Enter First Name"
                                            name="FirstName"
                                            value={formData.FirstName}
                                            onChange={handleChange}
                                        />
                                        {errors.FirstName && (
                                            <div className="error-message mandatorymark">
                                                {errors.FirstName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="last-name" className="userName loginlabel">
                                            Last Name
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.LastName ? "input-active" : ""
                                                }`}
                                            id="last-name"
                                            maxLength={50}
                                            placeholder="Enter Last Name "
                                            name="LastName"
                                            value={formData.LastName}
                                            onChange={handleChange}
                                        />
                                        {errors.LastName && (
                                            <div className="error-message mandatorymark">
                                                {errors.LastName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="title" className="userName loginlabel">
                                            Title
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.title ? "input-active" : ""
                                                }`}
                                            id="title"
                                            maxLength={50}
                                            placeholder="Enter Title "
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                        />
                                        {errors.title && (
                                            <div className="error-message mandatorymark">
                                                {errors.title}
                                            </div>
                                        )}
                                    </div>

                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="email" className="userName loginlabel">
                                            Email
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EmailId ? "input-active" : ""
                                                }`}
                                            id="email"
                                            placeholder="Enter Email "
                                            name="EmailId"
                                            value={formData.EmailId}
                                            onChange={handleChange}
                                        />
                                        {errors.EmailId && (
                                            <div className="error-message mandatorymark">
                                                {errors.EmailId}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label
                                            htmlFor="MobileNumber"
                                            className="userName loginlabel"
                                        >
                                            Mobile Phone
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.MobileNumber ? "input-active" : ""
                                                }`}
                                            id="MobileNumber"
                                            placeholder="Enter Mobile Phone"
                                            name="MobileNumber"
                                            value={displayValue}
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                        />
                                        {errors.MobileNumber && (
                                            <div className="error-message mandatorymark">
                                                {errors.MobileNumber}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-5 textleft">
                                        <label
                                            htmlFor="officePhone"
                                            className="userName loginlabel"
                                        >
                                            Office Phone
                                        </label>
                                        {/* <span className='mandatorymark loginlabel'>*</span> */}
                                        <input
                                            type="text"
                                            className={`form-control ${formData.officePhone ? "input-active" : ""
                                                }`}
                                            id="officePhone"
                                            placeholder="Enter Office Phone "
                                            name="officePhone"
                                            value={displayValueOffice}
                                            onKeyDown={handleOfficeKeyDown}
                                            onChange={handleChange}
                                        />
                                        {errors.officePhone && (
                                            <div className="error-message mandatorymark">
                                                {errors.officePhone}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className=" mb-3 d-flex justify-content-between "
                                        style={{ textAlign: "end" }}
                                    >
                                        <Link
                                            to="/entityinformation"
                                            className="joingeo"
                                            onClick={goback}
                                        >
                                            Go Back
                                        </Link>
                                        <button type="submit" className="nextBtn">
                                            Next
                                        </button>
                                    </div>
                                    <div className="joinus remember" style={{ textAlign: "end" }}>
                                        Already have an account?
                                        <a href="/login" className="joingeo">
                                            {" "}
                                            Log in
                                        </a>{" "}
                                        here.
                                    </div>
                                </form>
                            ) : (
                                <form onSubmit={handleButtonClick}>
                                    <div className="Username mb-2 textleft">
                                        <label htmlFor="username" className="userName loginlabel ">
                                            Entity Name
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EntityName ? "input-active" : ""
                                                }`}
                                            id="username"
                                            maxLength={50}
                                            name="EntityName"
                                            value={formData.EntityName}
                                            onChange={handleChange}
                                            placeholder="Enter Entity Name"
                                        />
                                        {errors.EntityName && (
                                            <div className="error-message mandatorymark">
                                                {errors.EntityName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="password" className="userName loginlabel">
                                            Entity Mailing address
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EnityMailingAdderess ? "input-active" : ""
                                                }`}
                                            id="password"
                                            maxLength={100}
                                            placeholder="Enter address "
                                            name="EnityMailingAdderess"
                                            value={formData.EnityMailingAdderess}
                                            onChange={(e) => {
                                                handleSearchChange(e);
                                            }}
                                        />
                                        {suggestions.length > 0 && (
                                            <ul className="search-results">
                                                {suggestions.map((result) => (
                                                    <li onClick={() => handleAddressSelect(result)}>
                                                        {result.freeformAddress}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {errors.EnityMailingAdderess && (
                                            <div className="error-message mandatorymark">
                                                {errors.EnityMailingAdderess}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="password" className="userName loginlabel">
                                            Entity Mailing address 2 (optional)
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EnityMailingAdderess2 ? "input-active" : ""
                                                }`}
                                            id="password"
                                            maxLength={50}
                                            placeholder="Enter address"
                                            name="EnityMailingAdderess2"
                                            value={formData.EnityMailingAdderess2}
                                            onChange={handleChange}
                                        />
                                        {/* {errors.UserName && <div className="error-message mandatorymark">{errors.UserName}</div>} */}
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6 Password mb-2 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                Country
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <input
                                                type="text"
                                                className={`form-control ${formData.Country ? "input-active" : ""
                                                    }`}
                                                id="password"
                                                placeholder="Enter Country "
                                                name="Country"
                                                disabled={true}
                                                value={formData.Country}
                                                onChange={handleChange}
                                                readOnly
                                                style={{ cursor: "not-allowed" }}
                                            />
                                            {errors.Country && (
                                                <div className="error-message mandatorymark">
                                                    {errors.Country}
                                                </div>
                                            )}
                                        </div>
                                        <div className=" col-md-6 Password mb-2 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                State
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <select
                                                className={`form-control ${formData.StateId ? "input-active" : ""
                                                    }`}
                                                id="state"
                                                name="StateId"
                                                value={formData.StateId}
                                                onChange={stateChange}
                                                disabled={formData.StateId ? true : false}
                                                style={{ cursor: formData.StateId ? "not-allowed" : "pointer" }}
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.id} value={state.iso2}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.StateId && (
                                                <div className="error-message mandatorymark">
                                                    {errors.StateId}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className=" col-md-6 Password mb-3 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                City
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <select
                                                className={`form-control ${formData.CityId ? "input-active" : ""
                                                    }`}
                                                id="city"
                                                name="CityId"
                                                value={formData.CityId}
                                                onChange={cityChange}
                                            >
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.id} value={city.iso2}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.CityId && (
                                                <div className="error-message mandatorymark">
                                                    {errors.CityId}
                                                </div>
                                            )}
                                        </div>
                                        <div className=" col-md-6 Password mb-2 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                ZIP Code
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <input
                                                type="text"
                                                maxLength="5"
                                                className={`form-control ${formData.Zipcode ? "input-active" : ""
                                                    }`}
                                                id="password"
                                                placeholder="Enter ZIP Code "
                                                name="Zipcode"
                                                value={formData.Zipcode}
                                                onChange={handleChange}
                                            />
                                            {errors.Zipcode && (
                                                <div className="error-message mandatorymark">
                                                    {errors.Zipcode}
                                                </div>
                                            )}
                                            {zipcodeWarning && (
                                                <div className="error-message mandatorymark">
                                                    {zipcodeWarning}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className=" mb-3" style={{ textAlign: "end" }}>
                                        <button type="submit" className='nextBtn'>Next</button>
                                    </div>
                                    <div className='joinus remember' style={{ textAlign: "end" }}>
                                        Already have an account?
                                        <a href='/Login' className='joingeo'> Log in</a> here.</div>
                                </form>
                            )

                            }

                        </div>
                    </div>
                </div>
            </div>
            <BootstrapDialog
                onClose={handleContinue}
                classes={{ paper: 'custom-paper-class' }}
                aria-labelledby="customized-dialog-title"

                dividers sx={{
                    textAlign: 'start',
                    overflowY: 'auto',
                    padding: '5rem 5rem',
                    borderRadius: '5px'
                }}
                open={open}
            >
                <DialogTitle dividers sx={{ textAlign: 'start', color: 'rgba(51, 51, 51, 1)', fontSize: "24px", fontWeight: "700",marginLeft:"20px" }} id="customized-dialog-title">
                   Email and Phone Verification
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent style={{ border: "none", padding: "12px 37px" }}>
                    <Typography gutterBottom>
                        <span>
                            {" "}
                           
                        </span>
                    </Typography>
                    <div>
                    </div>
                    <Typography gutterBottom>
                        <span>
                        For your security, you will need to complete a two-step verification process.<br/>
                        You will receive two verification codes: one on your registered email address: {formData?.EmailId} {" "}
                         and one on your registered phone number: {formData?.MobileNumber}.
                        </span>
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        className='mb-3'
                        autoFocus
                        classes={{ root: 'Secondary' }}
                        sx={{ width: '150px', height: '50px', backgroundColor: '#6BCD55', color: '#000', fontFamily: 'Montserrat', fontSize: '17px', fontStyle: 'normal', fontWeight: 700,backgroundColor:"lightgrey", '&:hover': { backgroundColor: '#A9A9A9' } }}
                    >
                        <span style={{ textTransform: 'capitalize' }}>Cancel</span>
                    </Button>
                    <Button
                        className='mb-3'
                        autoFocus
                        onClick={handleContinue}
                        classes={{ root: 'Secondary' }}
                        sx={{ width: '150px', height: '50px', backgroundColor: '#6BCD55', color: '#000', fontFamily: 'Montserrat', fontSize: '17px', fontStyle: 'normal', fontWeight: 700, '&:hover': { backgroundColor: '#5aa949' } }}
                       // disabled={!selectedValue}
                    >
                        <span style={{ textTransform: 'capitalize' }}>Continue</span>
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        </div>
    );
};

export default EntityInformation;
