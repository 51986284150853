import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import Navbar from '../NavBar/Navbar';
import { useNavigate } from 'react-router-dom';
const ViewPrakingLotInfo = (EmailId) => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const location = useLocation();
    const [parkingLotBookingDetails, setParkingLotBookingDetails] = useState([]);
    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const actionRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [rowData, setRowData] = useState([]);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    const navigate = useNavigate();

    function getLoggedInUser() {
		const loggedInUser = localStorage.getItem('LogedInUser');
		if (!loggedInUser) {
			console.error('No LoggedInUser found');
			return null;
		}
		try {
			const stateLoggedInUser = JSON.parse(loggedInUser);
			return stateLoggedInUser;
		} catch (error) {
			console.error('Error parsing LoggedInUser', error);
			return null;
		}
	}
    const LogedInUser = getLoggedInUser();
	const stateLogedInUser = {"EmailId":LogedInUser};
    useEffect(() => {
        const fetchParkingLotDetails = () => {
            axios.post(`${hostPath}/api/ParkingLotBookingDetails`, stateLogedInUser, { headers: { "x-functions-key":  `${hostHeader}` } })
                .then(response => {
                    if (response.data) {
                        console.log("ParkingLotBookingDetails Response", response.data);
                        setParkingLotBookingDetails(response.data);
                        setFilter(response.data);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        };
 
        fetchParkingLotDetails();
    }, [hostPath]);
 
    useEffect(() => {
        const result = parkingLotBookingDetails.filter((item) => {
            return (item.parkingLotAddress || item.createdDate)?.toLowerCase().includes(search.toLowerCase());
        });
        setFilter(result);
    }, [search, parkingLotBookingDetails]);
    const handleDelete = (val, typeOfButton) => {
        let data = { EmailId: val.emailId, Parking_Lot_ID: val.parking_Lot_ID }
        setRowData(data);
        setOpen(true);
        setPopupVisible(false);
    };
    const handleConfirm = (text) => {
        if (text == 'confirm') {
            const requestOptions = {
                method: 'POST',
                headers: { "x-functions-key": hostHeader },
                body: JSON.stringify(rowData)
            };
            axios.post(`${hostPath}/api/DeleteParkingLotDetailsByEmailId`, requestOptions.body, { headers: requestOptions.headers })
                .then(response => {
                    if (response.data) {
                        if (response.data.status) {
                            const newdata = parkingLotBookingDetails.filter((item) => item.parking_Lot_ID !== rowData.Parking_Lot_ID);
                            setParkingLotBookingDetails(newdata);
                            setFilter(newdata);
                            setOpen(false);
                        }
                        else {
                            alert(response.data.responseMessage);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (text == 'cancel') {
            setOpen(false);
        }
    };

    const handleEdit = (row) => {
        // Implement the edit functionality
        setPopupVisible(false);
    };

    const handleActionClick = (row, event) => {
        console.log("row",row)
        setSelectedRow(row);
        const rect = event.target.getBoundingClientRect();
        setPopupPosition({
            top: rect.bottom + window.scrollY - 75,  // Adding some offset
            right: rect.right + window.scrollX - 1450 // Center align with dots
        });
        setPopupVisible(true);
    };

    const columns = [
        {
            name: "Parking Lot Address",
            selector: (row) => row.parkingLotAddress,
        },
        {
            name: "Status",
            selector: () => "Active"
        },
        {
            name: "Designated Use",
            selector: (row) => row.price ? row.price : "Robot Taxi Parking & Pick-up/Drop-off",
        },
        {
            name: "Last Updated",
            selector: (row) => row.createdDate,
        },
        {
            name: "Actions",
            cell: (row) => (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0px' }}>
                  <FiEdit3 title="Edit" aria-label="Edit" style={{
                    width: "38px",
                    height: "25px",
                    padding: "2px 2px",
                    cursor:"pointer",
                    justifyContent: "center",
                    }}
                    onClick={() => handleEdit(row)} />
                  <FiTrash2 title="Delete" aria-label="Delete" style={{
                    width: "38px",
                    height: "25px",
                    padding: "2px 2px",
                    cursor:"pointer",
                    justifyContent: "center",
                    }}
                    onClick={()=> handleDelete(row)} />
              </div>
            ),
        },
    ];

    const tableHeaderStyle = {
        headCells: {
            style: {
                height: "90px",
                fontWeight: 800,
                fontSize: "16px",
                backgroundColor: "white",
                lineHeight: "19.2px",
                fontFamily: "Lato",
                textAlign: "left",
                borderBottom: "none"
            },
        },
    };

    const navigateToDashboard = () => {
        navigate('/dashborad');
    };

    return (
        <div>
               <Navbar/>
            <div className='container-fluid p-0'>
                <div className="lotmanagement">
                    <div className="row" style={{ textAlign: "left" }}>
                        <div className="col-8">
                            <div className='viewHeader'>Registered Parking Lots</div>
                            <div className='listview'>View the list of parking lots registered</div>
                            <div style={{ color: 'blue', cursor: 'pointer', marginBottom: '15px', fontSize: '16px' }} onClick={navigateToDashboard}>Sign up more parking lots</div>
                        </div>
                        <div className="col-4">
                            <input type='text' className="form-control" placeholder='Search by address.' onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "left" }}>
                        <div className="col-12 mb-2">
                            Total parking lots: {parkingLotBookingDetails.length}
                        </div>
                    </div>
                    <React.Fragment>
                        <DataTable
                            customStyles={{
                                headCells: tableHeaderStyle.headCells,
                                cells: {
                                    style: {
                                        fontFamily: 'Lato',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        lineHeight: '18.6px',
                                        textAlign: 'left',
                                        height: "50px",
                                        borderBottom: "none"
                                    },
                                },
                            }}
                            columns={columns}
                            data={filter}
                            pagination
                            fixedHeader
                            selectableRowsHighlight
                            highlightOnHover
                        />
                    </React.Fragment>
                </div>
            </div>
           <Dialog
            classes={{ paper: 'custom-paper-class-delete' }}
            aria-labelledby="customized-dialog-title"
            dividers
            open={open}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <DialogTitle dividers sx={{ textAlign: 'start', color: "#000;", fontSize: "24px", fontWeight: "700",    padding: "5px 24px "}} id="customized-dialog-title">
                Are you Sure You Want to Delete This Property?
            </DialogTitle>
            <DialogContent style={{ border: "none", padding: "5px 22px", color: "#000;" }}>
                <Typography gutterBottom>
                    <span>Once you delete this property, you will not be able to retrieve the data. This action is irreversible. Please confirm if you wish to proceed.</span>
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'right' }}>
                <Button
                    className='custom-button-cancel'
                    onClick={() => handleConfirm('cancel')}
                >
                    Cancel
                </Button>
                <Button
                    className='custom-button-delete'
                    onClick={() => handleConfirm('confirm')}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>

        </div>
    );
};

export default ViewPrakingLotInfo;
