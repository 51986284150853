import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import atlas from 'azure-maps-control';
import axios from 'axios';
const ReviewAndConfirm = (props) => {
    const { overrides, handleReview, areasMarked, addressvalue, accountinfo,EmailId,latitude,longitude,toggleCondition,handleviewparking,defaultaddress,...rest } = props;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [searchedLocation, setsearchedLocation] = useState('')
    const [BankDetails, setBankDetails] = useState({})
    const navigate = useNavigate();
    var map;
    useEffect(() => {
        (addressvalue.length !== 0) ?  setsearchedLocation(addressvalue) : setsearchedLocation(defaultaddress.trim());
        setBankDetails(accountinfo);
    }, [accountinfo,defaultaddress]);
    useEffect(() => {
        MapDetails();
    }, []);
    function MapDetails() {
        map = new atlas.Map('myMap', {
            center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
            zoom: 18,
            language: 'en-US',
            style: 'satellite',
            view: 'Auto',
            // Add authentication details for connecting to Azure Maps.
            authOptions: {
                authType: 'subscriptionKey',
                subscriptionKey: 'iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw'
            }
        });
        const zoomControl = new atlas.control.ZoomControl();
        map.controls.add(zoomControl, {
            position: 'bottom-right'
        });
    
        var myCustomJson = areasMarked;
        map.events.add('ready', function () {
            var points = [];
   
            if (myCustomJson.length > 0) {
                for (var i = 0; i < myCustomJson.length; i++) {
                    // Create a position object from the lon and lat values.
                    var position = new atlas.data.Position(myCustomJson[i]?.Coordinates?.Longitude, myCustomJson[i]?.Coordinates?.Latitude);
                    // Create a new property for the substring of Name.
                    var nameSubstring = myCustomJson[i]?.Name?.substring(0, 3);
    
                    // Create a Point feature and pass in the result object as the properties so that we can access them later if needed.
                    var point = new atlas.data.Feature(
                        new atlas.data.Point(position),
                        {
                            Title: myCustomJson[i]?.Name?.substring(0, 3),
                            NameSubstring: nameSubstring
                        }
                    );
    
                    points.push(point);
                }
            }
            var datasource = new atlas.source.DataSource();
            map.sources.add(datasource);
    
            // Add point data to the data source.
            datasource.add(points);
    
            // Create a layer that defines how to render the points on the map.
            var symbolLayer = new atlas.layer.SymbolLayer(datasource, null, {
                textOptions: {
                    textField: ['get', 'NameSubstring'],
                    offset: [0, 1.2],
                    color: 'white'
                }
            });
    
            map.layers.add(symbolLayer);
        });
    }
    
    const backButtonClick = () => {
        handleReview(false);
        toggleCondition('4 of 5 - Enter Bank Details')
        toggleCondition('5 of 5 - Review and Confirm')
        }
    const handleButtonClick = (e) => {
        const email1 = {
            "EmailId": EmailId,
            "ParkingLotAddress": searchedLocation,
            BankDetails
        };
        axios.post(`${hostPath}/api/ParkingLotBooking`, email1, { headers: { 
            "x-functions-key": hostHeader} })
            .then(response => {
                if (response.data) {
                    e.preventDefault();
                    navigate('/viewparkinginfo')
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    return (
        <div className="col-md-12 ">
            <div class="row m-4">
                <div className="col-lg-12 col-md-12 col-sm-12" style={{ textAlign: "left" }}>
                    <div><b>Review Marked Areas</b></div>
                    <div>Take a close look at the areas you've marked on the map to ensure they accurately reflect your intended designations.</div>
                </div>
            </div>


            <div class="row m-4"  >

                <div id="reviewAndConfirm" style={{}}>
                    <div class="row" style={{ textAlign: "left" }}>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div> <b>Property Name : {searchedLocation}</b></div>
                        </div>
                    </div>
                    <div class="row" style={{ textAlign: "left" }}>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div className='m-2'><b>Area Details:  </b></div>
                            <div>Pick up Entrance : Define the specific entrance location where passengers will be picked up by Robo Taxis</div>
                            <div>Pick up Exit : Identify the exit point for passengers after being dropped off by Robo Taxis.</div>
                        </div>
                    </div>
                    <div class="row "  >
                        <div class="col-lg-12 col-md-12 col-sm-12  m-3">
                            <div>
                                <div id="myMap" style={{ height: '650px', width: '100%', margin: '0% 0% 1% -1%' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" mb-3" style={{ textAlign: "end", position: "relative" }}>
                <button type="submit" className='finishbtn' onClick={backButtonClick} style={{ marginRight: "10px",position: "relative", right: "80px", bottom: "217px" }}>Go Back</button>
                <button type="submit" className='finishbtn' onClick={handleButtonClick} style={{ marginRight: "10px",position: "relative", right: "76px", bottom: "217px" }}>Finish</button>
            </div>
        </div>
    )
}
export default ReviewAndConfirm;