import React, { useState, useEffect } from "react";
import RobotaxiLogin from "../../Images/LoginImage.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AccountInformation = (props) => {
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const navigate = useNavigate();
  const EntityRegistration = props.loginUserDetails;
  const [errors, setErrors] = useState({});
  const [postId, setPostId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [rangeValue, setRangeValue] = useState(100);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    EmailId: "",
    Password: "",
    ConfirmPassword: "",
    TandC: false,
  });
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const backgroundStyle = {
    background: `linear-gradient(90deg, green ${rangeValue}%, rgb(230 224 224) )`,
  };

  useEffect(() => {
    const EntityRegistration = props.loginUserDetails;
    console.log("EntityRegistration", EntityRegistration);
    setFormData((prevState) => ({
      ...prevState,
      ...EntityRegistration,
    }));
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formData.TandC) {
      errors.TandC = "Agree Terms&Conditions";
    }

    if (!formData.EmailId) {
      errors.EmailId = "Email is required";
    }

    if (!formData.Password) {
      errors.Password = "Password is required";
    } else if (!validatePassword(formData.Password)) {
      errors.Password = "Passwords requirements are not satisified: ";
    }
    if (!formData.ConfirmPassword) {
      errors.ConfirmPassword = "Confirm Password is required";
    } else if (formData.Password != formData.ConfirmPassword) {
      errors.ConfirmPassword = "Please ensure the Passwords match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const registrationButtonClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const updatedFormData = { ...EntityRegistration, ...formData };
      const requestOptions = {
        method: "POST",
        headers: { "x-functions-key": `${hostHeader}` },
        body: JSON.stringify(updatedFormData),
      };
      axios
        .post(`${hostPath}/api/entityregistration`, requestOptions.body, {
          headers: requestOptions.headers,
        })
        .then((response) => {
          const errors = {};
          if (response.data.status === true) {
            setPostId(response.data.id);
            const LogedInUserRes = response.data.responseMessage;
            const userEmail = LogedInUserRes.split(":");
            const email = {
              emailId: userEmail[1].trim(),
            };
            var LogedInUser = [];
            LogedInUser.push(email);
            props.onAccountinfo();
            localStorage.setItem(
              "LogedInUser",
              JSON.stringify(LogedInUser[0]?.emailId)
            );
            localStorage.setItem(
              "firstName",
              JSON.stringify(updatedFormData?.FirstName)
            );
            localStorage.setItem(
              "lastName",
              JSON.stringify(updatedFormData?.LastName)
            );
            localStorage.setItem(
              "EnityMailingAdderess",
              JSON.stringify(updatedFormData?.EnityMailingAdderess)
            );
            navigate("/dashborad");
          } else {
            if (response.data.status === false) {
              errors.EmailId = response.data.responseMessage;
            }
          }
          setErrors(errors);
          return Object.keys(errors).length === 0;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      TandC: !formData.TandC,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const validatePassword = (password) => {
    const requirements = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
    };
    setPasswordRequirements(requirements); 
    const allRequirementsMet = Object.values(requirements).every(requirement => requirement);
    return allRequirementsMet; 
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    clearError(name); // Clear error for the corresponding input field
    if (name === "Password") {
      validatePassword(value);
    }
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: "" };
    });
  };
  
  const goBack = () => {
    props.onEnityInfo();
  };

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-7 p-0">
          <img
            src={RobotaxiLogin}
            alt="Login"
            style={{ width: "100%", height: "100vh" }}
          />
        </div>
        <div className="col-md-5">
          <div className="contain">
            <div className="registersteps formbox">Step 3 of 3</div>
            <input
              type="range"
              className="range-input"
              value={rangeValue}
              min="0"
              max="100"
              style={{
                ...backgroundStyle,
                width: "80%",
                height: "5px",
                padding: "0",
                borderRadius: "5.5px",
              }}
            />
            <form className="login-form ">
              <div className=" login">
                <h3 className="loginheader textleft">Account Information</h3>
                <p className="mb-4 loginDescription textleft">
                  Choose Email and Password to complete your registration.
                </p>
              </div>
              <div className="Username mb-3 textleft">
                <label htmlFor="EmailId" className="userName loginlabel ">
                  Email
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <input
                  type="text"
                  className={`form-control ${
                    formData.EmailId ? "input-active" : ""
                  }`}
                  id="EmailId"
                  placeholder="Enter Email"
                  name="EmailId"
                  value={formData.EmailId}
                  onChange={handleChange}
                  readOnly
                />
                {errors.EmailId && (
                  <div className="error-message mandatorymark">
                    {errors.EmailId}
                  </div>
                )}
              </div>
              <div className="Password mb-3 textleft">
              <style>
                {`
                  input::-ms-reveal {
                  display: none;
                  }
                `}
              </style>
                <label htmlFor="password" className="userName loginlabel">
                  Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className={`form-control ${
                      formData.Password ? "input-active" : ""
                    }`}
                    id="password"
                    placeholder="Enter Password"
                    name="Password"
                    value={formData.Password}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-password-visibility"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
                {errors.Password && (
                  <div className="error-message mandatorymark">
                    {errors.Password}
                  </div>
                )}
                <ul style={{ listStyleType: "none", paddingLeft: 0, paddingTop: 10 }}>
                  {" "}
                  {/* Remove padding to align to the extreme left */}
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.minLength ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.minLength ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    At least 8 characters
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.uppercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.uppercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one uppercase letter (A-Z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.lowercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.lowercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one lowercase letter (a-z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.digit ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.digit ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one digit (0-9)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.specialChar ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.specialChar ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one special character (!, @, #, $, %, ^,
                    &, *)
                  </li>
                </ul>
              </div>
              <div className="Password mb-3 textleft">
                <style>
                {`
                  input::-ms-reveal {
                  display: none;
                  }
                `}
                </style>
                <label
                  htmlFor="confirmPassword"
                  className="userName loginlabel"
                >
                  Confirm Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className={`form-control ${
                      formData.ConfirmPassword ? "input-active" : ""
                    }`}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    name="ConfirmPassword"
                    value={formData.ConfirmPassword}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-confirm-password-visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {confirmPasswordVisible ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </div>
                {errors.ConfirmPassword && (
                  <div className="error-message mandatorymark">
                    {errors.ConfirmPassword}
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between mb-5">
                <div className="remember">
                  <label className="control control--checkbox mb-0">
                    <input
                      type="checkbox"
                      checked={formData.TandC}
                      onChange={handleCheckboxChange}
                    />{" "}
                    <span className="">
                      I Agree to{" "}
                      <a className="joingeo" onClick={handleClickOpen}>
                        {" "}
                        terms and conditions
                      </a>{" "}
                    </span>
                    <div className="control__indicator"></div>
                  </label>
                  {errors.TandC && (
                    <div className="error-message mandatorymark">
                      {errors.TandC}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <Link onClick={goBack} className="joingeo">
                  Go Back
                </Link>
                <button
                  type="submit"
                  className="nextBtn"
                  onClick={registrationButtonClick}
                >
                  Register
                </button>
              </div>
              <div className="joinus remember" style={{ textAlign: "end" }}>
                Already have an account?
                <a href="/" className="joingeo">
                  {" "}
                  Log in
                </a>{" "}
                here.
              </div>
            </form>
          </div>
        </div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            dividers
            sx={{ textAlign: "center" }}
            id="customized-dialog-title"
          >
             Parking Lot Managment Terms of Service
          </DialogTitle>

          <DialogContent dividers>
            <Box
              sx={{
                textAlign: "start",
                maxHeight: "50vh",
                overflowY: "auto",
                padding: "0.5rem 5rem",
              }}
            >
              <Typography gutterBottom>
                <span className="subcontent">
                  {" "}
                  Date of Last Revision: June 20, 2024:
                </span>
              </Typography>
              <Typography gutterBottom>
              <h1>Terms of Service</h1>
              
            </Typography>

            <Typography gutterBottom>
              <h2>Introduction</h2>
              <p>
                GeoParking Technologies Inc. (“GeoParking” “GPT” “we” “us” “our”)
                provides its services (described below) to you through its website
                located at <a target= "_blank" href="https://www.geoparking.com"> https://www.geoparking.com</a> (the “Site”) and through its mobile applications (if any) and related
                services (collectively such services including any new features and
                applications and the Site the “Services”) subject to the following
                Parking Lot Management Terms of Service (as amended from time to time
                the “Terms” or “Terms of Service”). These Terms are solely intended to
                be used by owners or managers of parking lots or parking spaces who
                wish to use the Services to enable autonomous vehicles to use parking
                spots and lots within their control to pick-up and drop-off riders or
                to park between riders. For purposes of these Terms of Service we use
                the term “Robotaxi” to refer to any autonomous vehicle whether
                operated by a robotaxi business entity or owned by a private
                individual that has the capability to pick up carry and drop off
                passengers and park completely autonomously.
              </p>
              <p>
                Note that these Parking Lot Management Terms of Service are separate
                and distinct from GeoParking’s Website Terms of Use which govern the
                use of our website and related apps at <a target= "_blank" href="/terms-of-use"> Terms of Use</a>.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Key Provisions</h2>
              <p>
                <strong>Easy Termination By You:</strong> These Terms of Service can
                be terminated with 72 hours’ written (email to suffice) notice by you
                for any reason or no reason as stated in the Term and Termination
                section below.
              </p>
              <p>
                <strong>Arbitration:</strong> PLEASE READ THESE TERMS OF SERVICE
                CAREFULLY AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER
                IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS REMEDIES AND
                OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED
                EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND
                FINAL ARBITRATION AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE
                CLAIMS AGAINST GEOPARKING ON AN INDIVIDUAL BASIS NOT AS A PLAINTIFF OR
                CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING (2)
                YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY
                INJUNCTIVE AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS AND (3) YOU
                MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A
                JURY OR IN A COURT OF LAW. YOU CAN OPT OUT OF THIS AGREEMENT TO
                ARBITRATE BY CONTACTING <a target= "_blank" href="mailto:disputes@geoparking.com">disputes@geoparking.com</a>
                WITHIN 30 DAYS AFTER FIRST ACCEPTING THESE TERMS OF SERVICE AND
                STATING YOUR FIRST AND LAST NAME AND THAT YOU DECLINE THE TERMS TO
                ARBITRATE.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Access and Use of the Service</h2>
              <h3>Services Description</h3>
              <p>
                The Service offers a platform for entities or individuals—referred to
                as “Parking Spot Owners”—who own manage or hold rights to use parking
                spots in a particular location (e.g. commercial retail commercial
                industrial commercial office government educational residential home
                residential building/community religious establishment etc.). This
                platform allows Parking Spot Owners to (i) connect with owners and
                operators of Robotaxis or privately-owned autonomous vehicles that
                need a parking spot to use between fares; (ii) define limits on
                duration of use by such vehicles; and (iii) indicate specific spots
                (or general locations) for use by these vehicles during
                optionally-specified times all as further described by the Service’s
                Documentation (defined as the totality of the website help articles
                and technical documentation made available by us at <a target= "_blank" href="https://www.GeoParking.com">https://www.GeoParking.com</a>).
                Anyone who accesses or uses the Service whether as a Parking Spot
                Owner Robotaxi operator or owner of a private autonomous vehicle or
                acting on behalf of themselves or a third party will be referred to
                herein as a “user.”
              </p>
              <h3>Your Registration Obligations</h3>
              <p>
                You are required to register with GeoParking in order to access and
                use the Service. If you choose to use the Service you agree to provide
                and maintain true accurate current and complete information about
                yourself or the entity you represent as prompted by the Service’s
                registration form. Registration data and certain other information
                about you are governed by our <a target= "_blank"  href="/privacy-policy">Privacy Policy</a>. If you are under 18
                years of age you are not authorized to use the Service with or without
                registering. By accessing or using the Service you represent and
                warrant that you are at least 18 years of age and that you have not
                been previously suspended or removed from the Service.
              </p>
              <h3>Member Account Password and Security</h3>
              <p>
                If you register for a Parking Lot Management account you will be
                required to provide us with information about yourself such as your
                name email address mobile number and details about your parking
                spot(s). You agree that the information you provide to us is accurate
                and that you will keep it accurate and up to date at all times. Your
                account is personal to you (or the entity you represent) and your
                account and any related benefits including discount codes or similar
                benefits may not be shared with third parties including other users
                (unless such users are part of an entity’s account). You are
                responsible for maintaining the confidentiality of your password and
                account and are fully responsible for any and all activities that
                occur under your password or account. You agree to (a) immediately
                notify GeoParking of any unauthorized use of your password or account
                or any other breach of security and (b) ensure that you exit from your
                account at the end of each session when accessing the Service (or
                otherwise take reasonable steps to avoid the misuse of your account).
                GeoParking will not be liable for any loss or damage arising from your
                failure to comply with this Section. You further agree to indemnify
                defend and hold harmless the GeoParking Parties (as defined below)
                from and against any claims losses damages and other harm arising in
                connection with the unauthorized use (including without limitation any
                use by a person or entity other than you and any use not expressly
                permitted by these Terms of Service) of your account credentials.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Modifications to Service</h2>
              <p>
                GeoParking reserves the right to modify or discontinue temporarily or
                permanently the Service (or any part thereof) with or without notice.
                You agree that GeoParking will not be liable to you or to any third
                party for any modification suspension or discontinuance of the
                Service.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>General Practices Regarding Use and Data Retention</h2>
              <p>
                You acknowledge that GeoParking may establish general practices and
                limits concerning use of the Service including without limitation the
                maximum period of time that data or other content will be retained by
                the Service and the maximum storage space that will be allotted on
                GeoParking’s servers (or GeoParking’s account on a cloud service
                provider) on your behalf. You agree that GeoParking has no
                responsibility or liability for the deletion or failure to store any
                data or other content maintained or uploaded by the Service. You
                acknowledge that GeoParking reserves the right to terminate accounts
                that are inactive for an extended period of time. You further
                acknowledge that GeoParking reserves the right to change these general
                practices and limits at any time in its sole discretion with or
                without notice.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Conditions of Use</h2>
              <h3>Parking Lot Management Listings and Review</h3>
              <p>
                When creating a Robotaxi parking lot or spot listing (a “Listing”) one
                or more of the following details may be added at the sole discretion
                of the Parking Spot Owner as explained in the Documentation:
              </p>
              <ul>
                <li>Designated entrance(s) and exit(s) to the parking lot;</li>
                <li>
                  Designated pick up and drop off locations within or around the
                  parking lot;
                </li>
                <li>Designated parking spots within the parking lot and;</li>
                <li>
                  Day and time-based availability for parking designations as desired.
                </li>
              </ul>
              <p>
                Parking Spot Owners are responsible for providing complete and
                accurate information in order to make the above designations using the
                tools made available in the Services and described in the
                Documentation.
              </p>
              <p>
                Parking Spot Owners are solely responsible for keeping Listings
                (particularly day and time availability) up to date. By creating a
                Listing you hereby grant permission for GeoParking to conduct certain
                technical reviews of the Listing including but not limited to
                measurements of the parking spot and the parking lot in which the
                parking spot exists via satellite imagery publicly provided
                photographs and maps or live video LIDAR or radar footage of the
                Listing for the purpose of ensuring that Robotaxis can safely navigate
                to and use the Listing.
              </p>
              <p>
                Parking Spot Owners represent and warrant that any Listing they post
                and the sharing of such parking spot(s) will (i) not breach any
                agreements entered into with any third parties such as homeowners
                association condominium employment lease or other agreements and (ii)
                comply with all applicable laws (such as zoning laws) and other rules
                and regulations (including having all required permits licenses and
                registrations) and will in no event result in GeoParking violating any
                applicable law or being miscategorized as a parking operator of
                record.
              </p>
              <p>
                The utilization of the Listing by Robotaxis will vary and depends on a
                variety of factors such as Robotaxis’ search parameters needs and
                preferences price Listing size and availability of EV charging
                infrastructure and availability etc. As such GeoParking makes no
                representations regarding the number of times a Listing will be used
                which directly impacts the income derived from such Listing (see Fees
                below).
              </p>
              <p>
                By creating a Listing and using the Services you hereby grant all
                reasonably necessary licenses to all Robotaxis using the GeoParking
                Services to enter any premises needed to access the Listing. You also
                grant all reasonably necessary licenses to any persons or additional
                vehicles reasonably needed to fix malfunctioning Robotaxis which may
                include service personnel entering the premises and if necessary the
                use of tow trucks. You also acknowledge and agree that nothing in
                these Parking Lot Management Terms of Service substitutes for or
                eliminates the need for regular parking enforcement in your parking
                lots.
              </p>
              <h3>Fees</h3>
              <p>
                When Robotaxis park in your Listings they are charged parking fees.
                The amount of the parking fee is determined solely by GeoParking using
                multiple proprietary methods and is displayed in your GeoParking
                Parking Spot Owner account. If you at any time do not want to accept
                Robotaxis for the listed parking fee you may use the Services to
                temporarily disable your Listings as described by the Documentation.
                The parking fees are collected from the Robotaxi operator by
                GeoParking and distributed to you on a monthly basis using the
                financial account(s) designated by you in the Services. The percentage
                of the total parking fee you will receive is published in your account
                and may change from time to time. Fees will accumulate in your account
                and will be paid to you at the end of each month unless the total
                accumulated fee is less than $25 USD in which case GeoParking will
                rollover that amount into the next month. You understand and agree
                that providing GeoParking with incorrect financial account information
                may result in a delay in receiving parking fees.
              </p>
              <h3>Exclusivity</h3>
              <p>
                By using the Services you agree not to use any other similar service
                that purports to pay fees to Parking Spot Owners (or similar term) in
                exchange for allowing Robotaxis (or any similar term) to use their
                parking lots (or designated parking spots within their parking lots)
                as a location to park Robotaxis (a “Competing Service”). If you begin
                using a Competing Service you must immediately notify us of that fact
                which will also serve to immediately terminate these Parking Lot
                Management Terms of Service. For purposes of this section any
                parking-related service that helps human drivers find parking spots is
                not considered a Competing Service.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Intellectual Property Rights</h2>
              <h3>Service Content Software and Trademarks</h3>
              <p>
                You acknowledge and agree that the Service may contain content or
                features (“Service Content”) that are protected by copyright patent
                trademark trade secret or other proprietary rights and laws. Except as
                expressly authorized by GeoParking you agree not to modify copy frame
                scrape rent lease loan sell distribute or create derivative works
                based on the Service or the Service Content in whole or in part except
                that the foregoing does not apply to your own User Content (as defined
                below) that you legally upload to the Service. In connection with your
                use of the Service you will not engage in or use any data mining
                robots scraping or similar data gathering or extraction methods. If
                you are blocked by GeoParking from accessing the Service (including by
                blocking your IP address) you agree not to implement any measures to
                circumvent such blocking (e.g. by masking your IP address or using a
                proxy IP address). Any use of the Service or the Service Content other
                than as specifically authorized herein is strictly prohibited. The
                technology and software underlying the Service or distributed in
                connection therewith are the property of GeoParking our affiliates and
                our partners (the “Software”). You agree not to copy modify create a
                derivative work of reverse engineer reverse assemble or otherwise
                attempt to discover any source code sell assign sublicense or
                otherwise transfer any right in the Software. Any rights not expressly
                granted herein are reserved by GeoParking.
              </p>
              <p>
                The GeoParking name and logos are trademarks and service marks of
                GeoParking (collectively the “GeoParking Trademarks”). Other company
                product and service names and logos used and displayed via the Service
                may be trademarks or service marks of their respective owners who may
                or may not endorse or be affiliated with or connected to GeoParking.
                Nothing in these Terms of Service or the Service should be construed
                as granting by implication estoppel or otherwise any license or right
                to use any of GeoParking Trademarks displayed on the Service without
                our prior written permission in each instance. All goodwill generated
                from the use of GeoParking Trademarks will inure to our exclusive
                benefit.
              </p>
              <h3>Third Party Material/Services</h3>
              <p>
                Under no circumstances will GeoParking be liable in any way for any
                content or materials of any third parties (including users) including
                but not limited to for any errors or omissions in any content or for
                any loss or damage of any kind incurred as a result of the use of any
                such content. You acknowledge that GeoParking does not pre-screen
                content but that GeoParking and its designees will have the right (but
                not the obligation) in their sole discretion to refuse or remove any
                content that is available via the Service. Without limiting the
                foregoing GeoParking and its designees will have the right to remove
                any content that violates these Terms of Service or is deemed by
                GeoParking in its sole discretion to be otherwise objectionable. You
                agree that you must evaluate and bear all risks associated with the
                use of any content including any reliance on the accuracy completeness
                or usefulness of such content.
              </p>
              <p>
                The Services may provide you with access to third-party websites
                databases networks servers information software programs systems
                directories applications or products or services (collectively
                “Third-Party Services”). GeoParking does not have or maintain any
                control over Third-Party Services and is not and cannot be responsible
                for their content operation or use. By linking or otherwise displaying
                information from or providing access to any Third-Party Services
                GeoParking does not give any representation warranty or endorsement
                express or implied with respect to the legality accuracy quality or
                authenticity of content information or services provided by those
                Third-Party Services.
              </p>
              <p>
                Third-Party Services may have their own terms of use and privacy
                notices and may have different practices and requirements to those
                operated by GeoParking with respect to the Service. You are solely
                responsible for reviewing any terms of use privacy notice or other
                terms governing your use of these Third-Party Services which you use
                at your own risk. You are advised to make reasonable inquiries and
                investigations before entering into any transaction financial or
                otherwise and whether online or offline with any third party related
                to any Third-Party Services.
              </p>
              <p>
                You are solely responsible for taking the precautions necessary to
                protect yourself from fraud when using Third-Party Services and to
                protect your computer systems from viruses worms Trojan horses and
                other harmful or destructive content and material that may be included
                on or may emanate from any Third-Party Services. GeoParking disclaims
                any and all responsibility or liability for any harm resulting from
                your use of Third-Party Services and you hereby irrevocably waive any
                claim against GeoParking with respect to the content or operation of
                any Third-Party Services.
              </p>
              <p>
                The software you download consists of a package of components
                including certain third-party software (“Third-Party Software”)
                provided under separate license terms (the “Third Party Terms”). Your
                use of the Third-Party Software in conjunction with the Services in a
                manner consistent with the terms of these Terms is permitted however
                you may have broader rights under the applicable Third-Party Terms and
                nothing in these Terms is intended to impose further restrictions on
                your use of the Third-Party Software.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>User Content Transmitted Through the Service</h2>
              <p>
                With respect to the content or other materials you upload through the
                Service or share with other users or recipients (collectively “User
                Content”) including Listings and details of the Listings you represent
                and warrant that you own all right title and interest in and to such
                User Content including without limitation all copyrights and rights of
                publicity contained therein. By uploading any User Content you hereby
                grant and will grant GeoParking and its affiliated companies a
                nonexclusive worldwide royalty free fully paid up transferable
                sublicensable perpetual irrevocable license to copy display upload
                perform distribute store modify and otherwise use your User Content in
                connection with the operation of the Service or the promotion
                advertising or marketing thereof in any form medium or technology now
                known or later developed.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Feedback</h2>
              <p>
                You acknowledge and agree that any questions comments suggestions
                ideas feedback or other information about the Service (“Submissions”)
                provided by you to GeoParking are non-confidential and GeoParking will
                be entitled to the unrestricted use and dissemination of these
                Submissions for any purpose commercial or otherwise without
                acknowledgment or compensation to you.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Data Retention</h2>
              <p>
                You acknowledge and agree that GeoParking may preserve content and may
                also disclose content if required to do so by law or in the good faith
                belief that such preservation or disclosure is reasonably necessary
                to: (a) comply with legal process applicable laws or government
                requests; (b) enforce these Terms of Service; (c) respond to claims
                that any content violates the rights of third parties; or (d) protect
                the rights property or personal safety of GeoParking its users and the
                public. You understand that the technical processing and transmission
                of the Service including your content may involve (a) transmissions
                over various networks; and (b) changes to conform and adapt to
                technical requirements of connecting networks or devices.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Third Party Websites</h2>
              <p>
                The Service may provide or third parties may provide links or other
                access to other sites and resources on the Internet. GeoParking has no
                control over such sites and resources and GeoParking is not
                responsible for and does not endorse such sites and resources. You
                further acknowledge and agree that GeoParking will not be responsible
                or liable directly or indirectly for any damage or loss caused or
                alleged to be caused by or in connection with use of or reliance on
                any content events goods or services available on or through any such
                site or resource. Any dealings you have with third parties found while
                using the Service are between you and the third party and you agree
                that GeoParking is not liable for any loss or claim that you may have
                against any such third party.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Warranties and Disclaimers</h2>
              <p>
                To the fullest extent permitted by law the Services are provided “as
                is” with all faults and the entire risk as to satisfactory quality
                performance accuracy safety and effort is with you. You acknowledge
                that because of the nature of the internet mobile networks and the
                devices which access the internet and/or mobile networks the Services
                may not be accessible when needed and that information data audio and
                video transmitted over the internet and/or mobile networks may be
                subject to interruption or third-party interception and modification.
                To the fullest extent permitted by law GeoParking disclaims any and
                all warranties or conditions express or implied or statutory including
                but not limited to the implied warranties or conditions of
                merchantability fitness for a particular purpose quiet enjoyment
                safety non-infringement accuracy of informational content absence of
                viruses and damaging or disabling code tax-exempt status and any
                warranties arising out of course of dealing or usage or trade.
              </p>
              <p>
                To the fullest extent permitted by law none of GeoParking GeoParking’s
                affiliates employees agents contractors representatives content
                providers or service providers and none of the Parking Spot Owners of
                the parking lots or parking garages that supply the parking spots or
                their affiliates employees agents contractors representatives
                (collectively the “GeoParking Parties”) warrant the accuracy
                completeness or timeliness of the Services. The GeoParking Parties
                cannot and do not assume any responsibility for any loss damages or
                liabilities arising from the failure of any telecommunications
                infrastructure or the internet or for your misuse of any of content
                and information accessed through the Services. You expressly agree
                that your use of the Services and your reliance upon any of the
                respective content is at your sole risk.
              </p>
              <p>
                You acknowledge that GeoParking is not responsible for the Listings
                and parking spots provided by Parking Spot Owners and that it has no
                right to and does not control direct or supervise Parking Spot Owners
                Robotaxis or third parties who may be in attendance in the parking lot
                or spot.
              </p>
              <p>
                GeoParking does not create or deliver parking spots or Listings. To
                the maximum extent permitted by law the GeoParking Parties will not be
                liable for any damage arising out of or in connection with parking
                spots Listings or a Robotaxi’s use of any parking spot whether based
                on warranty contract tort (including negligence) product liability or
                any other legal theory. Whether you are a Parking Spot Owner or
                Robotaxi operator you alone are responsible for identifying
                understanding and complying with all laws that apply to your use of
                the Services.
              </p>
              <p>
                GeoParking makes no warranty that (i) the Services will meet your
                requirements (ii) the Services will be uninterrupted timely secure or
                error-free (iii) the results that may be obtained from the use of the
                service will be accurate or reliable or (iv) the quality of any
                products Services information or other material purchased or obtained
                by you through the service will meet your expectations.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Indemnity and Release</h2>
              <p>
                You agree to release defend indemnify and hold the GeoParking Parties
                harmless from any and all losses damages expenses including reasonable
                attorneys’ fees rights claims actions of any kind and injury
                (including death) arising out of or relating to your access to or use
                of the Service any User Content your connection to the Service your
                violation of these Terms of Service or your violation of any rights of
                another.
              </p>
              <p>
                To the fullest extent permitted by law you further waive release and
                forever discharge the GeoParking Parties from any and all
                responsibility or liability for injuries or damages resulting from any
                service obtained through the use of the Services including injuries or
                damages caused by the negligent act or omission of the GeoParking
                Parties or in any way arising out of or connected with the Services.
              </p>
              <p>
                We reserve the right at our own expense to assume the exclusive
                defense and control of any matter otherwise subject to indemnification
                by you and in such case you agree to cooperate with our defense of
                such claim. You will not in any event settle any claim or matter
                without our written consent.
              </p>
              <p>
                If you are a California resident you waive California Civil Code
                Section 1542 which says: “A general release does not extend to claims
                which the creditor does not know or suspect to exist in his favor at
                the time of executing the release which if known by him must have
                materially affected his settlement with the debtor.” If you are a
                resident of another jurisdiction you waive any comparable statute or
                doctrine.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Limitation of Liability</h2>
              <p>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT GEOPARKING WILL NOT BE LIABLE
                FOR ANY INDIRECT INCIDENTAL SPECIAL CONSEQUENTIAL EXEMPLARY DAMAGES OR
                DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO DAMAGES FOR
                LOSS OF GOODWILL USE DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
                GEOPARKING HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES)
                WHETHER BASED ON CONTRACT TORT NEGLIGENCE STRICT LIABILITY OR
                OTHERWISE RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE
                SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                RESULTING FROM ANY GOODS DATA INFORMATION OR SERVICES PURCHASED OR
                OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR
                FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
                ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO
                EVENT WILL GEOPARKING’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES LOSSES
                OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID GEOPARKING IN THE
                LAST SIX (6) MONTHS OR IF GREATER ONE HUNDRED DOLLARS ($100).
              </p>
              <p>
                SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
                WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL
                OR CONSEQUENTIAL DAMAGES. ACCORDINGLY SOME OF THE ABOVE LIMITATIONS
                SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO
                YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH
                THESE TERMS OF SERVICE YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                DISCONTINUE USE OF THE SERVICE.
              </p>
              <p>
                IF YOU ARE A USER FROM NEW JERSEY THE SECTIONS TITLED “DISCLAIMER OF
                WARRANTIES” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS
                BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF
                ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF
                THE STATE OF NEW JERSEY THE INVALIDITY OF SUCH PORTION SHALL NOT
                AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE
                SECTIONS.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Dispute Resolution By Binding Arbitration</h2>
              <p>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</p>
              <h3>a. Agreement to Arbitrate</h3>
              <p>
                This Dispute Resolution by Binding Arbitration section is referred to
                in these Parking Lot Management Terms of Service as the “Arbitration
                Agreement.” You agree that any and all disputes or claims that have
                arisen or may arise between you and GeoParking whether arising out of
                or relating to this Parking Lot Management Terms of Service (including
                any alleged breach thereof) the Services any advertising any aspect of
                the relationship or transactions between us shall be resolved
                exclusively through final and binding arbitration rather than a court
                in accordance with the terms of this Arbitration Agreement except that
                you may assert individual claims in small claims court if your claims
                qualify. Further this Arbitration Agreement does not preclude you from
                bringing issues to the attention of federal state or local agencies
                and such agencies can if the law allows seek relief against us on your
                behalf. You agree that by entering into these Parking Lot Management
                Terms of Service you and GeoParking are each waiving the right to a
                trial by jury or to participate in a class action. Your rights will be
                determined by a neutral arbitrator not a judge or jury. The Federal
                Arbitration Act governs the interpretation and enforcement of this
                Arbitration Agreement.
              </p>
              <h3>
                b. Prohibition of Class and Representative Actions and
                Non-Individualized Relief
              </h3>
              <p>
                YOU AND GEOPARKING AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
                OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
                MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
                UNLESS BOTH YOU AND GEOPARKING AGREE OTHERWISE THE ARBITRATOR MAY NOT
                CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY
                NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED REPRESENTATIVE
                OR CLASS PROCEEDING. ALSO THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
                MONETARY INJUNCTIVE AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
                INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
                PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S) EXCEPT
                THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC
                INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE
                ENFORCEABILITY OF THIS PROVISION.
              </p>
              <h3>c. Pre-Arbitration Dispute Resolution</h3>
              <p>
                GeoParking is always interested in resolving disputes amicably and
                efficiently and most customer concerns can be resolved quickly and to
                the customer’s satisfaction by emailing customer support at <a target= "_blank" href="mailto:Disputes@GeoParking.com">Disputes@GeoParking.com</a>.
                If such efforts prove unsuccessful a party who intends to seek
                arbitration must first send to the other by certified mail a written
                Notice of Dispute (“Notice”). The Notice to GeoParking should be sent
                to GeoParking Technologies Inc. 800 Bellevue Way NE Suite #500
                Bellevue WA 98004 (“Notice Address”). The Notice must (i) describe the
                nature and basis of the claim or dispute and (ii) set forth the
                specific relief sought. If GeoParking and you do not resolve the claim
                within sixty (60) calendar days after the Notice is received you or
                GeoParking may commence an arbitration proceeding. During the
                arbitration the amount of any settlement offer made by GeoParking or
                you shall not be disclosed to the arbitrator until after the
                arbitrator determines the amount if any to which you or GeoParking is
                entitled.
              </p>
              <h3>d. Arbitration Procedures</h3>
              <p>
                Arbitration will be conducted by a neutral arbitrator in accordance
                with the American Arbitration Association’s (“AAA”) rules and
                procedures including the AAA’s Consumer Arbitration Rules
                (collectively the “AAA Rules”) as modified by this Arbitration
                Agreement. For information on the AAA please visit its website <a target= "_blank" href="http://www.adr.org">http://www.adr.org</a>. Information about
                the AAA Rules and fees for consumer disputes can be found at the AAA’s
                consumer arbitration page <a  target= "_blank" href="http://www.adr.org/consumer_arbitration">
                http://www.adr.org/consumer_arbitration</a>. If there is any
                inconsistency between any term of the AAA Rules and any term of this
                Arbitration Agreement the applicable terms of this Arbitration
                Agreement will control unless the arbitrator determines that the
                application of the inconsistent Arbitration Agreement terms
                would not result in a fundamentally fair arbitration. The arbitrator
                must also follow the provisions of these Parking Lot Management Terms
                of Service as a court would. All issues are for the arbitrator to
                decide including but not limited to issues relating to the scope
                enforceability and arbitrability of this Arbitration Agreement.
                Although arbitration proceedings are usually simpler and more
                streamlined than trials and other judicial proceedings the arbitrator
                can award the same damages and relief on an individual basis that a
                court can award to an individual under the Parking Lot Management
                Terms of Service and applicable law. Decisions by the arbitrator are
                enforceable in court and may be overturned by a court only for very
                limited reasons.
              </p>
              <p>
                Unless GeoParking and you agree otherwise any arbitration hearings
                will take place in a reasonably convenient location for both parties
                with due consideration of their ability to travel and other pertinent
                circumstances. If the parties are unable to agree on a location the
                determination shall be made by AAA. If your claim is for $10000 or
                less GeoParking agrees that you may choose whether the arbitration
                will be conducted solely on the basis of documents submitted to the
                arbitrator through a telephonic hearing or by an in-person hearing as
                established by the AAA Rules. If your claim exceeds $10000 the right
                to a hearing will be determined by the AAA Rules. Regardless of the
                manner in which the arbitration is conducted the arbitrator shall
                issue a reasoned written decision sufficient to explain the essential
                findings and conclusions on which the award is based.
              </p>
              <h3>e. Costs of Arbitration</h3>
              <p>
                Payment of all filing administration and arbitrator fees (collectively
                the “Arbitration Fees”) will be governed by the AAA Rules unless
                otherwise provided in this Arbitration Agreement. If the value of the
                relief sought is $25000 or less at your request GeoParking will pay
                all Arbitration Fees. If the value of relief sought is more than
                $25000 and you are able to demonstrate to the arbitrator that you are
                economically unable to pay your portion of the Arbitration Fees or if
                the arbitrator otherwise determines for any reason that you should not
                be required to pay your portion of the Arbitration Fees GeoParking
                will pay your portion of such fees. In addition if you demonstrate to
                the arbitrator that the costs of arbitration will be prohibitive as
                compared to the costs of litigation GeoParking will pay as much of the
                Arbitration Fees as the arbitrator deems necessary to prevent the
                arbitration from being cost-prohibitive. Any payment of attorneys’
                fees will be governed by the AAA Rules.
              </p>
              <h3>f. Confidentiality</h3>
              <p>
                All aspects of the arbitration proceeding and any ruling decision or
                award by the arbitrator will be strictly confidential for the benefit
                of all parties.
              </p>
              <h3>g. Severability</h3>
              <p>
                If a court or the arbitrator decides that any term or provision of
                this Arbitration Agreement (other than the subsection (b) titled
                “Prohibition of Class and Representative Actions and
                Non-Individualized Relief” above) is invalid or unenforceable the
                parties agree to replace such term or provision with a term or
                provision that is valid and enforceable and that comes closest to
                expressing the intention of the invalid or unenforceable term or
                provision and this Arbitration Agreement shall be enforceable as so
                modified. If a court or the arbitrator decides that any of the
                provisions of subsection (b) above titled “Prohibition of Class and
                Representative Actions and Non-Individualized Relief” are invalid or
                unenforceable then the entirety of this Arbitration Agreement shall be
                null and void unless such provisions are deemed to be invalid or
                unenforceable solely with respect to claims for public injunctive
                relief. The remainder of the Parking Lot Management Terms of Service
                will continue to apply.
              </p>
              <h3>h. Future Changes to Arbitration Agreement</h3>
              <p>
                Notwithstanding any provision in these Terms of Service to the
                contrary GeoParking agrees that if it makes any future change to this
                Arbitration Agreement (other than a change to the Notice Address)
                while you are a user of the Services you may reject any such change by
                sending GeoParking written notice within thirty (30) calendar days of
                the change to the Notice Address provided above. By rejecting any
                future change you are agreeing that you will arbitrate any dispute
                between us in accordance with the language of this Arbitration
                Agreement as of the date you first accepted these Parking Lot
                Management Terms of Service (or accepted any subsequent changes to
                these Parking Lot Management Terms of Service).
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Termination</h2>
              <h3>By GeoParking</h3>
              <p>
                You agree that GeoParking in its sole discretion may suspend or
                terminate your account (or any part thereof) or use of the Service and
                remove and discard any content within the Service for any reason
                including without limitation for lack of use or if GeoParking believes
                that you have violated or acted inconsistently with the letter or
                spirit of these Parking Lot Management Terms of Service. Any suspected
                fraudulent abusive or illegal activity that may be grounds for
                termination of your use of Service may be referred to appropriate law
                enforcement authorities. GeoParking may also in its sole discretion
                and at any time discontinue providing the Service or any part thereof
                with or without notice. You agree that any termination of your access
                to the Service under any provision of this Parking Lot Management
                Terms of Service may be affected without prior notice and acknowledge
                and agree that GeoParking may immediately deactivate or delete your
                account and all related information and files in your account and/or
                bar any further access to such files or the Service. Further you agree
                that GeoParking will not be liable to you or any third party for any
                termination of your access to the Service.
              </p>
              <h3>By Users</h3>
              <p>
                You may terminate your account and this Agreement at any time for any
                reason or no reason by providing 72 hours’ written notice to
                GeoParking. You must send an email to <a  target= "_blank" href="mailto:Cancellations@GeoParking.com">
                  Cancellations@GeoParking.com</a> with the subject line “CANCEL” using the email address connected to
                your account. You should include your name and phone number in the
                body of the email in case we need to contact you regarding your
                request.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>User Disputes</h2>
              <p>
                You agree that you are solely responsible for your interactions to the
                extent possible now or in the future with any other user in connection
                with the Service and GeoParking will have no liability or
                responsibility with respect thereto. GeoParking reserves the right but
                has no obligation to become involved in any way with disputes between
                you and any other user of the Service.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>General</h2>
              <h3>Entire Agreement</h3>
              <p>
                These Parking Lot Management Terms of Service constitute the entire
                agreement between you and GeoParking and govern your use of the
                Service superseding any prior agreements between you and GeoParking
                with respect to the Service. You also may be subject to additional
                terms and conditions that may apply when you use affiliate or
                third-party services third-party content or third-party software.
              </p>
              <h3>Governing Law</h3>
              <p>
                These Parking Lot Management Terms of Service will be governed by the
                laws of the State of Washington without regard to its conflict of law
                provisions. With respect to any disputes or claims not subject to
                arbitration as set forth above you and GeoParking agree to submit to
                the personal and exclusive jurisdiction of the state and federal
                courts located within King County Washington.
              </p>
              <h3>Waiver</h3>
              <p>
                The failure of GeoParking to exercise or enforce any right or
                provision of these Parking Lot Management Terms of Service will not
                constitute a waiver of such right or provision.
              </p>
              <h3>Severability</h3>
              <p>
                If any provision of these Parking Lot Management Terms of Service is
                found by a court of competent jurisdiction to be invalid the parties
                nevertheless agree that the court should endeavor to give effect to
                the parties’ intentions as reflected in the provision and the other
                provisions of these Parking Lot Management Terms of Service remain in
                full force and effect.
              </p>
              <h3>Limitation of Actions</h3>
              <p>
                You agree that regardless of any statute or law to the contrary any
                claim or cause of action arising out of or related to use of the
                Service or these Parking Lot Management Terms of Service must be filed
                within one (1) year after such claim or cause of action arose or be
                forever barred.
              </p>
              <h3>Admissibility</h3>
              <p>
                A printed version of this agreement and of any notice given in
                electronic form will be admissible in judicial or administrative
                proceedings based upon or relating to this agreement to the same
                extent and subject to the same conditions as other business documents
                and records originally generated and maintained in printed form.
              </p>
              <h3>Assignment</h3>
              <p>
                You may not assign these Parking Lot Management Terms of Service
                without the prior written consent of GeoParking but GeoParking may
                assign or transfer these Parking Lot Management Terms of Service in
                whole or in part without restriction.
              </p>
              <h3>Section Titles</h3>
              <p>
                The section titles in these Parking Lot Management Terms of Service
                are for convenience only and have no legal or contractual effect.
              </p>
              <h3>Notices</h3>
              <p>
                Notices to you may be made via either email or regular mail. The
                Service may also provide notice to you of changes to these Parking Lot
                Management Terms of Service or other matters by displaying notices or
                links to notices generally on the Service.
              </p>
              <h3>Third-Party Beneficiaries</h3>
              <p>
                The provisions of these Terms relating to the rights of the GeoParking
                Parties are intended for the benefit of such GeoParking Parties and
                such GeoParking Parties as third-party beneficiaries will be entitled
                to enforce such provisions in accordance with their terms irrespective
                of the fact that they are not signatories to or otherwise bound by
                these Terms.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Your Privacy</h2>
              <p>
                At GeoParking we endeavor to respect the privacy of our users. For
                details please see our <a target= "_blank" href="/privacy-policy">Privacy Policy</a>.
              </p>
            </Typography>

            <Typography gutterBottom>
              <h2>Notice for California Users</h2>
              <p>
                Under California Civil Code Section 1789.3 users of the Service from
                California are entitled to the following specific consumer rights
                notice: The Complaint Assistance Unit of the Division of Consumer
                Services of the California Department of Consumer Affairs may be
                contacted in writing at 1625 North Market Blvd. Suite N 112 Sacramento
                CA 95834 or by telephone at (916) 445-1254 or (800) 952-5210. You may
                contact us at <a target= "_blank" href="mailto:Support@GeoParking.com">Support@GeoParking.com</a> or
                at GeoParking Technologies Inc. 800 Bellevue Way NE Suite #500
                Bellevue WA 98004.
              </p>
            </Typography>

            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              className="secondary"
              autoFocus
              onClick={handleClose}
              classes={{ root: "Secondary" }}
            >
              <span>Close</span>
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default AccountInformation;
